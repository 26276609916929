import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useGTMPageView = () => {
  const location = useLocation();

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.pathname + window.location.search,
        title: document.title,
      },
    });
  }, [location]);
};

export default useGTMPageView;
