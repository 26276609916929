import React from 'react';
import ReactDOM from 'react-dom/client';
import "./assets/css/index.css";
import "./config";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { CartProvider } from './contexts/CartContext';
import { AccountProvider } from './contexts/AccountContext';
import { WishlistProvider } from './contexts/WishlistContext';
import { InformationProvider } from './contexts/InformationContext';
import 'typeface-lora';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <CartProvider>
        <AccountProvider>
          <WishlistProvider>
            <InformationProvider>
    <App />
    </InformationProvider>
          </WishlistProvider>
        </AccountProvider>
      </CartProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
