import React, { useEffect } from 'react'
  import NodataImg from '../assets/images/search-not-found-eh.png';
  import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft } from 'react-icons/fa';
const NoData = () => {
    const navigate = useNavigate()
  
  return (
    <div className="flex items-center justify-center  py-20 flex-col">
        <div className=' md:w-72 w-64 aspect-auto'>
<img src = {NodataImg}/>
</div>
<div className=' flex flex-col gap-3 md:flex-row justify-center text-center'>
<button onClick={()=>navigate(-1)} className=' px-3  py-2 rounded-md bg-dgrey2  text-dborderblack2 transition-all  hover:bg-dborderblack2 hover:text-dwhite1 flex flex-row gap-3 justify-center group font-bold text-sm'><span className=' group-hover:-translate-x-1 duration-400 delay-150 transition-all my-auto'><FaArrowLeft/></span> <span>Go Back</span></button>
   
    <button onClick={()=>navigate("/")} className=' px-3  py-2 rounded-md bg-dborderblack2 text-white font-bold text-sm'>Continue Shopping</button>
  
    </div>
  </div>
  )
}

export default NoData