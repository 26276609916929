function LatestPlaceholder() {
  return (
    <div className="animate-pulse container mb-12">
      <div className="w-full mx-4">
        {/* Options */}
        <div className=" h-12 mt-10 mb-5 flex justify-between">
          <div className="placeholder_animation_latest bg-white h-full w-3/12"></div>
          <div className="placeholder_animation_latest bg-white w-3/12"></div>
        </div>
        {/* Categories */}
        <div className="grid grid-cols-5 gap-3">
          <div className="placeholder_animation_latest h-80 bg-white"></div>
          <div className="placeholder_animation_latest h-80 bg-white"></div>
          <div className="placeholder_animation_latest h-80 bg-white"></div>
          <div className="placeholder_animation_latest h-80 bg-white"></div>
          <div className="placeholder_animation_latest h-80 bg-white"></div>
          <div className="placeholder_animation_latest h-80 bg-white"></div>
          <div className="placeholder_animation_latest h-80 bg-white"></div>
          <div className="placeholder_animation_latest h-80 bg-white"></div>
          <div className="placeholder_animation_latest h-80 bg-white"></div>
          <div className="placeholder_animation_latest h-80 bg-white"></div>
          <div className="placeholder_animation_latest h-80 bg-white"></div>
          <div className="placeholder_animation_latest h-80 bg-white"></div>
          <div className="placeholder_animation_latest h-80 bg-white"></div>
          <div className="placeholder_animation_latest h-80 bg-white"></div>
          <div className="placeholder_animation_latest h-80 bg-white"></div>
          <div className="placeholder_animation_latest h-80 bg-white"></div>
          <div className="placeholder_animation_latest h-80 bg-white"></div>
          <div className="placeholder_animation_latest h-80 bg-white"></div>
          <div className="placeholder_animation_latest h-80 bg-white"></div>
          <div className="placeholder_animation_latest h-80 bg-white"></div>
        </div>
      </div>
    </div>
  );
}

export default LatestPlaceholder;
