import React from "react";
import cityCenterLocation from "../assets/images/city-center-location.png";
import cityMallLocation from "../assets/images/city-mall.png";
import zahleLocation from "../assets/images/zahleLocation.png";

function StoreLocator() {
  return (
    <div className="md:container py-7 tracking-tighter px-4">
      <div className="w-full text-d27 font-bold flex justify-start mb-6">
        Our Stores
      </div>
      <div className="flex flex-col gap-5 md:gap-8">
        <div className="w-full flex flex-col md:flex-row gap-5">
          <div className="md:w-1/3 flex flex-col gap-2 justify-start items-start">
            <div className="text-d20 font-bold text-start">
              City Center, 2nd floor
            </div>
            <div>Beirut - Lebanon</div>
            <div>Phone: +961 76 400 008</div>
            <div>
              <a href="https://maps.app.goo.gl/HQ5kDtSi8fMuDnSF8" target="_blank" rel="noopener noreferrer">
                <img
                  className="w-full cursor-pointer"
                  src={cityCenterLocation}
                  alt="city-center-location"
                />
              </a>
            </div>
          </div>
          <div className="border-t border-gray-300 md:border-0"></div>
          <div className="md:w-1/3 flex flex-col gap-2 justify-start items-start">
            <div className="text-d20 font-bold text-start">
              City Mall, <span className="italic text-d17">Ground floor</span>
            </div>
            <div>Beirut - Lebanon</div>
            <div>Phone: +961 76 400 008</div>
            <div>
              <a href="https://maps.app.goo.gl/EDJ44uN5bv9vodko6" target="_blank" rel="noopener noreferrer">
                <img
                  className="w-full cursor-pointer"
                  src={cityMallLocation}
                  alt="city-mall-location"
                />
              </a>
            </div>
          </div>
          <div className="border-t border-gray-300 md:border-0"></div>
          <div className="md:w-1/3 flex flex-col gap-2 justify-start items-start">
            <div className="text-d20 font-bold text-start">
              Zone gate, <span className="italic text-d17">Mekseh</span>
            </div>
            <div>Zahle - Lebanon</div>
            <div>Phone: +961 76 400 008</div>
            <div>
              <a href="https://maps.app.goo.gl/cAcTpK2KnJ6KACmf7" target="_blank" rel="noopener noreferrer">
                <img
                  className="w-full cursor-pointer"
                  src={zahleLocation}
                  alt="zahle-store-location"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoreLocator;
