import React, { useEffect, useState, useRef, useContext } from "react";
import {
  useLocation,
  useParams,
  useNavigate,
  Link,
  useNavigationType,
} from "react-router-dom";
import _axios from "../axios";
import SingleProductCategory from "../components/SingleProductCategory";
import queryString from "query-string";
import { CiFilter } from "react-icons/ci";
import { BiSortAlt2 } from "react-icons/bi";
import buildLink, { path, pixelID } from "../urls";
import { RiArrowRightSLine } from "react-icons/ri";
import ReactPaginate from "react-paginate";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import TopCart from "../components/TopCart";
import WidgetsLoopMobile from "../components/WidgetsLoopMobile";
import WidgetsLoop from "../components/WidgetsLoop";
import ReactPixel from "react-facebook-pixel";
import { AccountContext } from "../contexts/AccountContext";
import Cookies from "js-cookie";
import CategoryPlaceholder from "../components/CategoryPlaceholder";
import CategoryMobilePlaceholder from "../components/CategoryMobilePlaceholder";
import DOMPurify from "dompurify";
import { FaAngleDown, FaSort } from "react-icons/fa";
import NoData from "../components/NoData";
import Slider from "react-slick";
function Category() {
  const location = useLocation();
  const params = useParams();
  const id = useParams().id;
  const path1 = location.pathname;
  const [data, setData] = useState({});
  const [filters, setfilters] = useState({});
  const [widgets, setWidgets] = useState({})
  // const [userFilters, setUserFilters] = useState({});
  const [pointer, setPointer] = useState(true);
  const [sort, setSort] = useState("Default");
  const [showSort, setShowSort] = useState(false);
  const [view, setView] = useState(3);
  const [filterMobile, setFilterMobile] = useState([]);
  const [filterMobileShow, setFilterMobileShow] = useState(false);
  const [showCartmenu, setShowCartmenu] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [hoveredCart, setHoveredCart] = useState(false);
  const [showWidgets, setShowWidgets] = useState(true);
  const [stateAccount] = useContext(AccountContext);
  const [isOpenCategories, setOpenCategories] = useState(false);
  const [openedDropdown, setOpenedDropdown] = useState(null);
  const [noData, setNoData] = useState(false);
  const [loading, setLoading] = useState(false);



  // const { userFilters, setUserFilters } = useFiltersContext();
  const [userFilters, setUserFilters] = useState({
    filter_sellers: [],
    filter_categories: [],
    filter_manufacturers: [],
    adv_filters: [],
    filter_options: [],
    filter_attributes: [],
  });
  const wrapperRef = useRef(null);

  const urlRef = useRef(location?.pathname);
  const navigate = useNavigate();
  const pathname = location.pathname;
  const navType = useNavigationType();
  const width = window.innerWidth;
  const parsedQueryString = queryString.parse(location.search);
  const parsedQueryStringNavigate = queryString.parse(location.pathname);

  //console.log(parsedQueryStringNavigate);

  function showCart() {
    setShowCartmenu(true);
    setOverlay(true);
    setTimeout(() => {
      setShowCartmenu(false);
      setOverlay(false);
    }, 3000);
  }


  function handleOpenDropdownBtn(button) {
    if (button == openedDropdown) {
      setOpenedDropdown(null)
    } else {
      setOpenedDropdown(button)
    }
  }


  useEffect(() => {
    setOpenCategories(false)
  }, [location])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (window.location.href.indexOf("all") > 0) {
      setShowWidgets(false);
    }
    var newPath;
    var type;
    var url = new URL(window.location);

    if (window.location.href.indexOf("has_filter") > 0) {
      type = "filter";
      if (url.searchParams.get("filter_categories") != null)
        newPath +=
          "&filter_categories=" + url.searchParams.get("filter_categories");
      if (url.searchParams.get("filter_sellers") != null)
        newPath += "&filter_sellers=" + url.searchParams.get("filter_sellers");
      if (url.searchParams.get("filter_attributes") != null)
        newPath +=
          "&filter_attributes=" + url.searchParams.get("filter_attributes");
      if (url.searchParams.get("filter_manufacturers") != null)
        newPath +=
          "&filter_manufacturers=" +
          url.searchParams.get("filter_manufacturers");
      if (url.searchParams.get("adv_filters") != null)
        newPath += "&adv_filters=" + url.searchParams.get("adv_filters");
      if (url.searchParams.get("filter_options") != null)
        newPath += "&filter_options=" + url.searchParams.get("filter_options");

      newPath = "&path=" + id + newPath;

      if (window.location.href.indexOf("last")) {
        newPath += "&last=" + url.searchParams.get("last");
      }
    } else {
      newPath = id;
      if (window.location.href.indexOf("c=") > 0) type = "category";
      if (window.location.href.indexOf("m=") > 0) type = "manufacturer";
      if (window.location.href.indexOf("s=") > 0) type = "seller";
    }

    if (location.pathname !== urlRef.current) {
      setUserFilters({
        filter_sellers: [],
        filter_categories: [],
        filter_manufacturers: [],
        adv_filters: [],
        filter_options: [],
        filter_attributes: [],
      });
      urlRef.current = location.pathname;
    }

    if (navType === "POP") {
      let sellerIndex;
      let brandIndex;
      let optionsIndex;
      let advfiltersIndex;
      let categoryIndex;
      let attrIndex;

      sellerIndex = window.location.href.indexOf("filter_sellers");
      brandIndex = window.location.href.indexOf("filter_manufacturers");
      categoryIndex = window.location.href.indexOf("filter_categories");
      advfiltersIndex = window.location.href.indexOf("adv_filters");
      optionsIndex = window.location.href.indexOf("filter_options");
      attrIndex = window.location.href.indexOf("filter_attributes");

      const value = Math.max(
        sellerIndex,
        brandIndex,
        optionsIndex,
        advfiltersIndex,
        categoryIndex,
        attrIndex
      );
      if (value === sellerIndex) {
        setUserFilters({
          ...userFilters,
          filter_options: [],
          filter_categories: [],
          filter_manufacturers: [],
          adv_filters: [],
          filter_attributes: [],
        });
      }
      if (value === categoryIndex) {
        setUserFilters({
          ...userFilters,
          filter_options: [],
          filter_manufacturers: [],
          filter_sellers: [],
          adv_filters: [],
          filter_attributes: [],
        });
      }
      if (value === brandIndex) {
        setUserFilters({
          ...userFilters,
          filter_sellers: [],
          filter_categories: [],
          filter_options: [],
          adv_filters: [],
          filter_attributes: [],
        });
      }
      if (value === attrIndex) {
        setUserFilters({
          ...userFilters,
          filter_sellers: [],
          filter_categories: [],
          filter_options: [],
          adv_filters: [],
        });
      }
      if (value === advfiltersIndex) {
        setUserFilters({
          ...userFilters,
          filter_sellers: [],
          filter_categories: [],
          filter_manufacturers: [],
          filter_options: [],
          filter_attributes: [],
        });
      }
    }

    let url_type =
      window.location.href.indexOf("has_filter") < 0 ? "default" : "filter";
    setData({});

    if (url_type === "default") {
      const q_s = queryString.parse(location.search);
      // q_s.page = q_s.page ? q_s.page : page;
      // q_s.limit = q_s.limit ? q_s.limit : limit.value;
      q_s.sort = q_s.sort ? q_s.sort : "p2co.sort_order";
      q_s.order = q_s.order ? q_s.order : "ASC";
      // if (width > 650) {
      q_s.source_id = 1;
      setLoading(true);
      _axios
        .post(
          buildLink(type, undefined, undefined) +
          id +
          `${stateAccount.admin ? "&adm_quantity=true&source_id=1" : "&source_id=1"}`
        )
        .then((response) => {
          setData(response?.data?.data);
          setfilters(response?.data?.data?.filters);
          setWidgets(response?.data?.data?.desktop_widgets);
          setPointer(true);
          setLoading(false)
        });
    } else {
      const q_s = queryString.parse(location.search);
      // q_s.page = q_s.page ? q_s.page : page;
      // q_s.limit = q_s.limit ? q_s.limit : limit.value;
      q_s.sort = q_s.sort ? q_s.sort : "p2co.sort_order";
      q_s.order = q_s.order ? q_s.order : "ASC";
      // if (width > 650) {
      q_s.source_id = 1;
      // }

      newPath += "&" + queryString.stringify(q_s);
      if (stateAccount.admin) {
        newPath += "&adm_quantity=true";
      }
      setLoading(true);
      _axios
        .post(
          buildLink(type, undefined, undefined) +
          newPath.replace("undefined", "")
        )
        .then((response) => {
          setLoading(false)
          //  console.log("_____________________________");
          //  console.log(response.data.data);
          if (response?.data?.data.products) {
            setData(response?.data?.data);
            setfilters(response?.data?.data?.filters);
            setPointer(true);
          } else {
            setNoData(true);
          }

        });
    }
    let productArray = [];
    let productDetails = [];
    if (!stateAccount.admin) {
      data?.products?.map((p) => {
        //console.log(p.product_id);
        productArray.push(p.product_id);
        productDetails.push({ id: p.product_id, quantity: p.quantity });
      });
      if (productArray?.length > 0 && !stateAccount.admin) {
        // ---> Facebook PIXEL <---
        const advancedMatching = {
          em: data?.social_data?.email,
          fn: data?.social_data?.firstname,
          ln: data?.social_data?.lastname,
          external_id: data?.social_data?.external_id,
          country: data?.social_data?.country_code,
          fbp: Cookies.get("_fbp"),
        };
        // window.fbq('init', pixelID, advancedMatching);

        ReactPixel.init(pixelID, advancedMatching, {
          debug: true,
          autoConfig: false,
        });
        ReactPixel.pageView();
        ReactPixel.fbq("track", "PageView");

        if (data) {
          window.fbq(
            "track",
            "ViewContent",
            {
              content_type: "product",
              content_ids: productArray,
              contents: productDetails,
              content_name: data?.social_data?.name,
            },
            { eventID: data?.social_data?.event_id }
          );
        }
      }
    }
  }, [location, sort, stateAccount.admin]);




  useEffect(() => {
    if (data && data.social_data) {

      var dataSocial = data.social_data;
      dataSocial["fbp"] = Cookies.get("_fbp");
      dataSocial["fbc"] = Cookies.get("_fbc");
      dataSocial["ttp"] = Cookies.get("_ttp");
      dataSocial["link"] = window.location.href;
      _axios
        .post(buildLink("pixel", undefined, window.innerWidth), dataSocial)
        .then((response) => {
          const data = response.data;
          if (data.success === true) {
          }
        });
    }
  }, [data])




  function getType() {
    if (window.location.href.indexOf("c=") > 0) return "category";
    if (window.location.href.indexOf("m=") > 0) return "manufacturer";
    if (window.location.href.indexOf("s=") > 0) return "seller";
  }
  function pageSetter(page) {
    //g(parseInt(page["selected"]));
    const new_page = parseInt(page["selected"]) + 1;
    pushRoute({ page: new_page });
  }
  // Set Sort
  function sortSetter(sort) {
    setSort(sort);
    setShowSort(false);
    let val = sort["value"];
    let order = "";
    let _sort = "";
    const i_o = val.indexOf("-");
    _sort = val.substring(0, i_o);
    order = val.substring(i_o + 1);
    const obj = { sort: _sort, order: order };
    setState(sort);
  }

  // Push Route
  function pushRoute(data) {
    const q_s = queryString.parse(location.search);
    //console.log(data);
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        q_s[key] = element;
      }
    }
    // alert(window.location.search);
    const params = new URLSearchParams(window.location.href);
    params.get("page");

    if (params.get("page") > 0) {
      // alert(pathname);
      const paramPage = new URLSearchParams(queryString.stringify(q_s));

      navigate(
        pathname.replace(
          "page=" + params.get("page"),
          "page=" + paramPage.get("page")
        )
      );
      // console.log(
      //   "page=" + params.get("page"),
      //   "page=" + paramPage.get("page")
      // );
      // alert(pathname)
    } else {
      navigate(
        pathname.replaceAll("&has_filter=true", "?has_filter=true") +
        "&" +
        queryString
          .stringify(q_s)
          .replaceAll("%2C", ",")
          .replaceAll("&has_filter=true", "?has_filter=true")
      );
    }
  }
  function setState(sort) {
    //console.log(sort);
    setSort(sort.text);
    let val = sort["value"];
    let order = "";
    let _sort = "";
    const i_o = val.indexOf("-");
    _sort = val.substring(0, i_o);
    order = val.substring(i_o + 1);
    var i_sort = pathname.indexOf("&sort=");
    // alert(pathname)
    var l = "";

    if (window.location.href.indexOf("has_filter=true") < 0) {
      if (i_sort > 0) {
        l = path1.substring(0, i_sort);
        // alert(l);
      } else {
        l = path1;
      }
    } else {
      // alert(location.search.indexOf("&sort="))
      if (location.search.indexOf("&sort=") > -1) {
        l = location.search.substring(0, location.search.indexOf("&sort="));
      } else {
        l = window.location.search;
        // alert(l)
      }
    }

    setShowSort(false);

    navigate((l + "&sort=" + _sort + "&order=" + order).replaceAll("/&", "&"));
  }
  function parseFilter(typekey, filter) {
    setPointer(false);

    const id = filter["id"];
    var last = "";
    let type_key = typekey;
    last = filter["last"];
    let values_array = userFilters[type_key];
    let c;
    let indexOfId = -1;
    let url1 = new URL(window.location);
    var filter_type = typekey;
    c = url1.searchParams.get(filter_type);

    if (c !== null) {
      indexOfId = c.split(",").indexOf(filter["id"]);
    }
    if (indexOfId < 0) {
      //console.log(values_array);
      // if(values_array===undefined){
      values_array = userFilters["filter_attributes"];
      // }
      //console.log(values_array);
      if (filter_type === "adv_filters")
        values_array = userFilters[type_key].push(filter["id"]);
      else values_array.push(filter["id"]);

      setUserFilters({
        ...userFilters,
        type_key: values_array,
      });

      let active_filters = {};
      for (const key in userFilters) {
        if (Object.hasOwnProperty.call(userFilters, key)) {
          const element = userFilters[key];

          if (element.length > 0 && key !== "type_key") {
            active_filters[key] = element;
          }
        }
      }
      var l = window.location.pathname;
      let query = "?has_filter=true&";
      let q = new URLSearchParams(active_filters).toString();

      q = decodeURIComponent(q);
      query += q;

      const i_p = window.location.pathname.indexOf("&page");
      if (i_p > 0) {
        l = l.substring(0, i_p);
      }
      const i_o = window.location.pathname.indexOf("&sort");

      if (i_o > 0 || i_p > 0) {
        if (i_o > 0) {
          l = l.substring(0, i_o);
        }

        navigate(l + query + "&last=" + last);
      } else {
        navigate(window.location.pathname + query + "&last=" + last);
      }
      // const  = window.location.pathname.indexOf("&page");

      // console.log(
      //   params.name +
      //     "/" +
      //     getType().slice(0, 1) +
      //     "=" +
      //     id +
      //     query +
      //     "&last=" +
      //     last
      // );
    } else {
      let query = type_key + "=" + id;
      let q = new URLSearchParams(query).toString();
      q = decodeURIComponent(q);

      let url1 = "?has_filter=false";
      values_array.pop();

      setUserFilters({
        ...userFilters,
        type_key: values_array,
      });

      if (location.search.indexOf(id) > -1) {
        let c = "";
        var array;
        let lastLink;
        // alert(window.location)
        // alert(window.location.search)
        url1 = new URL(window.location);
        c = url1.searchParams.get(filter_type);
        if (c != null) {
          array = c.split(",");
          let indexOfId = array.indexOf(id);
          let lengthArray = array.length;
          if (indexOfId >= 0) {
            lastLink = url1.searchParams.get("last");
            var page = url1.searchParams.get("page");

            if (indexOfId === 0 && lengthArray === 1) {
              if (location.search.indexOf("&" + q) > 0) {
                url1 = location.search.replace("&" + q, "");
              } else {
                url1 = location.search.replace(q, "");
              }

              if (url1 === "?has_filter=true&last=" + lastLink) {
                url1 = "";
              }
              if (url1 !== "") {
                url1 = url1
                  .toString()
                  .replace("&last=" + lastLink, "&last=" + last)
                  ?.replace("&page=" + page, "");
              }
            } else if (indexOfId === 0 && lengthArray > 1) {
              url1 = location.search.replace(id + ",", "");
              url1 = url1
                .toString()
                .replace("&last=" + lastLink, "&last=" + last);
            } else {
              url1 = location.search.replace("," + id, "");
              url1 = url1
                .toString()
                .replace("&last=" + lastLink, "&last=" + last)
                ?.replace("&page=" + page, "");
            }
          }
        }
        // console.log(path + query + "&last=" + last);

        navigate(pathname + url1);
      } else {
        navigate("/" + path1);
        // console.log(path + query + "&last=" + last);
      }
    }
  }
  // Check filter
  function checkFilter(type, name, filter) {
    var url = new URL(window.location);
    var c = url.searchParams.get(type);

    let array = Array("");
    array[type] = c?.split(",");
    // console.log("type, name, filter");
    // console.log(type, name, filter);
    if (
      name === "Color" ||
      name === "DIMENSIONS" ||
      name === "Size" ||
      1 === 1
    ) {
      if (c !== null && array[type].includes(filter["id"]) === true) {
        return true;
      } else {
        return false;
      }
    } else {
      if (c !== null && array[type].includes(filter["id"]) === true) {
        return "text-dblue2 underline underline-offset-4";
      } else {
        return false;
      }
    }
  }
  function showMobilefilter() {
    setFilterMobileShow(true);
    setShowSort(false);
  }


  const productSetting = {
    speed: 200,
    slidesToShow: 8,
    slidesToScroll: 3,
    infinite: false,
    prevArrow: "<",
    nextArrow: ">"
  };

  return (

    <div className=" bg-dgrey10 pt-1 w-full mt-3">
      <div className="md:container">
        {loading ? (
          <>
            {window.innerWidth > 650 ? (
              <CategoryPlaceholder />
            ) : (
              <CategoryMobilePlaceholder />
            )}

            {/* <Loader /> */}
          </>

        ) : data.products ? (

          <>


            <div className="  flex  gap-2 flex-col justify-start align-baseline text-start pt-4 pb-2 pl-8 items-start text-d16 text-dblack1 capitalize">
              <div className=" text-sm flex w-fit overflow-x-auto">
                <div className="flex items-center ">
                  <Link
                    to="/"
                    className=" md:block text-dborderblack0 font-light truncate  md:text-tiny mr-2 hover:text-dblue"
                    dangerouslySetInnerHTML={{
                      __html: "Home",
                    }}
                  />{" "}
                  <RiArrowRightSLine className="font-light mt-0.5 -mx-2 " />
                </div>
                {data?.breadcrumbs[0].category?.map((bread, index) => (
                  <Link
                    key={index}
                    to={
                      path +
                      "/" +
                      bread?.category_name
                        .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                        .replace(/\s+/g, "-")
                        .replace("/", "-")
                        .replace("%", "") +
                      "/c=" +
                      bread.category_id
                    }
                    className="flex  items-center"

                  >
                    <p dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(bread.category_name),
                    }} className="  text-dbasenavy mx-2 whitespace-nowrap font-semibold">

                    </p>
                    {index !== data?.breadcrumbs[0].category.length - 1 && <RiArrowRightSLine className=" text-dborderblack0 font-light mt-0.5 -mx-2 " />}
                  </Link>

                ))}
                
                {/* <div className="w-full my-auto text-left">
                  <div className="flex">
                    <div
                      className=" cursor-pointer ml-3 text-center rounded-lg bg-white border border-dbgrey1 w-44"
                      onClick={() => setShowSort(!showSort)}
                    >
                      <div className=" flex justify-center text-center gap-2">
                        <div
                          className=""
                          dangerouslySetInnerHTML={{
                            __html: sort?.text ? sort?.text : sort,
                          }}
                        ></div>
                        <FaSort className=" text-dgrey5 my-auto" />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`w-44 rounded-lg mt-1 bg-white absolute z-10 ml-3 ${!showSort && "hidden"
                      }`}
                  >
                    {data?.sorts?.map((sort) => (
                      <div
                        className="pl-5 py-1 cursor-pointer"
                        onClick={() => sortSetter(sort)}
                        dangerouslySetInnerHTML={{
                          __html: sort.text,
                        }}
                      ></div>
                    ))}
                  </div>
                </div> */}
              </div>

              
              {window.innerWidth > 650 && (
                <div className=" text-left text-dgrey9   text-sm  text-6 ">
                  {data?.products?.length} products
                </div>

              )}
            </div>

            {widgets &&
                    (data?.category_widget_status === "1" ||
                      data?.desktop_widget_status === "1") &&
                    widgets?.map((widget) => (
                      <div key={widget.mobile_widget_id}>
                        {" "}
                        <WidgetsLoop
                          widget={widget}
                        />{" "}
                      </div>
                    ))}
            <div className="border border-t-0 ml-8 mr-5 pt-5 pb-5"></div>

            <div
              className={`flex flex-row mt-2 ${!pointer && "pointer-events-none select-none opacity-25"
                }`}
            >


              {window.innerWidth > 650 ? (
                <div className="w-full">
                  <div className="flex w-full justify-between   pb-2">








                    <div className="w-full flex flex-row justify-start gap-3 px-5">
                      {data.categories && data.categories.length > 0 && <div className="relative ">
                        <button onClick={() => {
                          handleOpenDropdownBtn('categories')
                        }} className=" border border-dgrey6 text-dblack1  font-bold flex justify-center gap-4 text-center rounded-lg px-2 py-2"><span dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(data?.breadcrumbs[0].category[data?.breadcrumbs[0].category.length - 1]?.category_name),
                        }} ></span><FaAngleDown className={`${openedDropdown == 'categories' ? "rotate-180" : "rotate-0"} transition-all duration-500 my-auto`} /></button>

                        <div className=" absolute overflow-hidden top-[120%]">
                          <div className={`${openedDropdown == 'categories' ? "px-4 py-2  h-40" : " h-0"}  overflow-scroll relative duration-500 w-72 rounded-lg border-dgrey5   transition-all bg-white z-20 h-36 `}>

                            {data.categories && data?.categories?.map((category) => (
                              <div className="w-full text-left pt-1">
                                <Link
                                  className="hover:underline font-semibold py-4"
                                  to={
                                    "/" +
                                    category?.name
                                      ?.replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                                      .replace(/\s+/g, "-")
                                      .replace("..", "")
                                      .replace("/", "-")
                                      .replace("---", "-")
                                      .replace("--", "-")
                                      .replace("/", "") +
                                    "/c=" +
                                    category.id
                                  }
                                >
                                  {category?.name}
                                </Link>
                              </div>
                            ))}
                          </div>

                        </div>




                      </div>}





                      {Object.keys(filters).map((key) =>
                        filters[key].attribute_group_id !== "14" &&
                        filters[key].attribute_group_id !== "34" &&
                        filters[key].attribute_group_id !== "23" &&
                        filters[key].attribute_group_id !== "22" &&
                        filters[key].attribute_group_id !== "13" &&
                        filters[key].attribute_group_id !== "19" &&
                        filters[key].attribute_group_id !== "11" && // material
                        filters[key].attribute_group_id !== "9" && ( // pattern
                          //  ((filterCount === 0  &&  filters[key].attribute_group_id !== "12") || (filterCount === 1  &&  filters[key].attribute_group_id !== "18") ||  (filterCount === 2  &&  filters[key].attribute_group_id !== "4"))  &&
                          <div key={key} className="relative">
                            {filters[key]?.items.length > 0 && (
                              <button onClick={() => {
                                handleOpenDropdownBtn(filters[key]?.name)
                              }} className=" border  border-dgrey6 text-dblack1  font-bold flex justify-center gap-4 text-center rounded-lg px-3 py-2">
                                <span>{filters[key]?.name}</span><FaAngleDown className={`${openedDropdown == filters[key]?.name ? "rotate-180" : "rotate-0"} transition-all duration-500 my-auto`} />
                              </button>
                            )}

                            <div className=" absolute overflow-hidden top-[120%]">
                              <div className={`${openedDropdown == filters[key]?.name ? "px-4 py-2  h-40" : " h-0"}  overflow-scroll relative duration-500 w-72 rounded-lg border-dgrey5   transition-all bg-white z-20 h-36 `}>

                                {filters[key]?.items.length > 0 &&
                                  filters[key].name === "Colorex" ? (
                                  <div
                                    className={`${filters[key]?.items.length > 6 &&
                                      "h-36 "
                                      }`}
                                  >
                                    <div className="grid grid-cols-4 w-3/4 ">
                                      {filters[key]?.items?.map((filter) => (
                                        <div className="text-left w-full my-1">
                                          <img
                                            src={filter.image}
                                            alt={filter.name}
                                            className={`rounded-full w-8   ${checkFilter(
                                              filters[key].id,
                                              filters[key].name,
                                              filter
                                            )}`}
                                            onClick={() =>
                                              parseFilter(filters[key].id, filter)
                                            }
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className={`${filters[key]?.items.length > 6 &&
                                      "h-56 "
                                      }`}
                                  >
                                    {filters[key]?.items?.map((filter) =>
                                      filters[key].attribute_group_id !== "14" &&
                                        filters[key].attribute_group_id !== "23" &&
                                        filters[key].attribute_group_id !== "22" &&
                                        filters[key].attribute_group_id !== "13" ? (
                                        <div
                                          className={`w-full  bg-white text-dborderblack0 text-d16 leading-snug  py-1 my-2 cursor-pointer `}
                                        // onClick={() =>
                                        //   parseFilter(filters[key].id, filter)
                                        // }
                                        >
                                          <div className=" flex flex-row justify-start gap-3 ">
                                            <input
                                              checked={checkFilter(
                                                filters[key].id,
                                                filters[key].name,
                                                filter
                                              )}
                                              onChange={() => parseFilter(filters[key].id, filter)} type="checkbox" id={`filter_${filter.id}`} />
                                            <label htmlFor={`filter_${filter.id}`}> {filter.name}</label>

                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className={`text-left w-full hover:underline underline-offset-4 py-1 text-dborderblack0 text-d16 leading-snug pointer-events-auto cursor-pointer ${checkFilter(
                                            filters[key].id,
                                            filter.name,
                                            filter
                                          )}`}
                                          onClick={() =>
                                            parseFilter(filters[key].id, filter)
                                          }
                                        >
                                          {/* {filter.name} */}
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>

                          </div>


                        ))}




                    </div>

                    <div className="relative mt-2 ">
                      <div className="flex-row flex">
                      <h5 className=" whitespace-nowrap">Sort by:</h5>
                      <div className="w-full my-auto text-left">
                    
                        <div className="flex">
                          
                          <div
                            className=" cursor-pointer ml-3 text-center rounded-lg bg-white border border-dbgrey1 w-35"
                            onClick={() => setShowSort(!showSort)}
                          >
                            <div className=" flex justify-center text-center gap-2 ml-3">
                              <div
                                className=""
                                dangerouslySetInnerHTML={{
                                  __html: sort?.text ? sort?.text : sort,
                                }}
                              ></div>
                              <FaSort className=" text-dgrey5 my-auto" />
                            </div>
                          </div>
                        </div>
                        <div
                          className={`w-44 rounded-lg mt-1 bg-white absolute z-10 ml-3 ${!showSort && "hidden"
                            }`}
                        >
                          {data?.sorts?.map((sort) => (
                            <div
                              className="pl-5 py-1 cursor-pointer"
                              onClick={() => sortSetter(sort)}
                              dangerouslySetInnerHTML={{
                                __html: sort.text,
                              }}
                            ></div>
                          ))}
                        </div>
                      </div>
                      </div>
</div>


                  </div>
                  













                  <div className="border border-t-0 mx-3"></div>

                  <div
                    className={`grid ${view !== 2 && view !== 4
                      ? "grid-cols-3"
                      : "grid-cols-" + view
                      } `}
                  >
                    {data?.products?.map((product) => (
                      <div className="">
                        <SingleProductCategory
                          item={product}
                          showCartmenu={showCart}
                        ></SingleProductCategory>
                      </div>
                      
                    ))}
                  </div>
                  {/* Pagination */}
                  {Math.ceil(data["product_total"] / data.limit) > 1 && (
                    <ReactPaginate
                      pageCount={Math.ceil(data["product_total"] / data.limit)}
                      containerClassName={"category-pagination"}
                      onPageChange={pageSetter}
                      pageRangeDisplayed={width > 650 ? 2 : 1}
                      marginPagesDisplayed={width > 650 ? 1 : 1}
                      previousLabel={<BsChevronLeft className="mt-1 pt-0.5" />}
                      previousLinkClassName={"arrowLink"}
                      nextLinkClassName={"arrowLink"}
                      nextLabel={<BsChevronRight className="mt-1 pt-0.5" />}
                      activeClassName={"active-pagination-category"}
                      forcePage={
                        parsedQueryStringNavigate.page
                          ? parseInt(parsedQueryStringNavigate.page) - 1
                          : 0
                      }
                    ></ReactPaginate>
                  )}
                </div>
              ) : (
                <div style={{ width: window.innerWidth }}>
                  <div className="grid grid-cols-2 ">
                    <div className="flex w-full " onClick={showMobilefilter}>
                      <span className="w-2/6"></span>
                      <span>Filter</span>
                      <span className="mt-1 mx-4">
                        <CiFilter />
                      </span>
                    </div>
                    <div
                      className="border-l flex w-full"
                      onClick={() => setShowSort(showSort ? false : true)}
                    >
                      <span className="w-2/6"></span>
                      <span>Sort</span>
                      <span className="mt-1 mx-4">
                        <BiSortAlt2 />
                      </span>
                      {/* <div
                        className={`w-52 bg-white absolute z-20 top-52  -mt-3 text-left border border-dblack1 ${
                          !showSort && "hidden"
                        }`}
                      >
                        {data?.sorts?.map((sort) => (
                          <div
                            className="pl-5 py-2 "
                            onClick={() => sortSetter(sort)}
                            dangerouslySetInnerHTML={{
                              __html: sort.text,
                            }}
                          ></div>
                        ))}
                      </div> */}
                      <div
                        className={`absolute z-20 mt-36 text-left shadow-2xl shadow-black  bg-white ${!showSort ? 'hidden' : 'block'
                          } ${window.innerWidth < 640 ? 'w-40 top-14' :
                            window.innerWidth < 768 ? 'w-44 top-16' :
                              'w-52 top-20'
                          }`}
                        >
                        {data?.sorts?.map((sort) => (
                          <div
                            key={sort.text}
                            className="px-5 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => sortSetter(sort)}
                            dangerouslySetInnerHTML={{ __html: sort.text }}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="flex w-11/12 overflow-x-auto  scroll-auto	py-1">
                    {data?.categories?.map((category) => (
                      <div className=" text-left pt-1 px-2  p-2 hover:text-white hover:bg-dblack1  border border-dblack1 rounded-full text-dblack1 font-bold m-2 w-auto">
                        <Link
                          className="hover:underline  text-d9  py-4 truncate"
                          to={
                            "/" +
                            category?.name
                              ?.replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                              .replace(/\s+/g, "-")
                              .replace("..", "")
                              .replace("/", "-")
                              .replace("---", "-")
                              .replace("--", "-")
                              .replace("/", "") +
                            "/c=" +
                            category.id
                          }
                        >
                          {category?.name}
                        </Link>
                      </div>
                    ))}
                  </div>
                  {width < 650 && (

                    <div className="grid grid-cols-1 ">
                      {widgets &&
                        widgets?.map((widget) => (
                          <div key={widget.mobile_widget_id}>
                            {" "}
                            <WidgetsLoop
                              widget={widget}
                            />{" "}
                          </div>
                        ))}
                    </div>

                  )}

                  <div className="grid grid-cols-2">



                    {data?.products?.map((product) => (
                      <div className="">
                        <SingleProductCategory
                          item={product}
                        ></SingleProductCategory>
                      </div>
                    ))}
                  </div>

                  {/* Pagination */}
                  {Math.ceil(data["product_total"] / data.limit) > 1 && (
                    <ReactPaginate
                      pageCount={Math.ceil(data["product_total"] / data.limit)}
                      containerClassName={"category-pagination"}
                      onPageChange={pageSetter}
                      pageRangeDisplayed={width > 650 ? 2 : 2}
                      marginPagesDisplayed={width > 650 ? 1 : 1}
                      previousLabel={<BsChevronLeft className="mt-1 pt-0.5" />}
                      previousLinkClassName={"arrowLink"}
                      nextLinkClassName={"arrowLink"}
                      nextLabel={<BsChevronRight className="mt-1 pt-0.5" />}
                      activeClassName={"active-pagination-category"}
                      forcePage={
                        parsedQueryStringNavigate.page
                          ? parseInt(parsedQueryStringNavigate.page) - 1
                          : 0
                      }
                    ></ReactPaginate>
                  )}
                  <div
                    className={`absolute z-50 top-36  bg-white w-3/4 h-4/6 mx-12 pb-2 overflow-y-scroll  ${!filterMobileShow && "hidden"
                      }`}
                  >
                    <div className="text-left w-full py-3 px-3  text-dbasenavy text-d18 font-bold bg-dyellow2 ">
                      Filter
                    </div>

                    { }
                    {Object.keys(filters).map(
                      (key) =>
                        filters[key]?.items.length > 0 && (
                          <div
                            key={key}
                            className=" p-3 m-3  text-d14 bg-dyellow2 shadow-sm  shadow-slate-400"
                          >
                            {
                              <div
                                className={`flex w-full text-left text-dblack2 uppercase `}
                                onClick={() =>
                                  !filterMobile.includes(
                                    filters[key].attribute_group_id
                                  )
                                    ? setFilterMobile((filterMobile) => [
                                      ...filterMobile,
                                      filters[key].attribute_group_id,
                                    ])
                                    : setFilterMobile((filterMobile) =>
                                      filterMobile.filter(
                                        (filterMobile) =>
                                          filterMobile !==
                                          filters[key].attribute_group_id
                                      )
                                    )
                                }
                              >
                                <div className="w-11/12">
                                  {filters[key].name}{" "}
                                </div>
                                <div className="text-black font-medium">
                                  {filterMobile.includes(
                                    filters[key].attribute_group_id
                                  )
                                    ? "-"
                                    : "+"}
                                </div>
                              </div>
                            }
                            {filters[key].name === "Colore" ? (
                              <div
                                className={` my-5 ${filters[key]?.items.length > 6 &&
                                  !filterMobile.includes(filters[key].id) &&
                                  " overflow-y-auto h-36 px-2"
                                  }  ${!filterMobile.includes(filters[key].id)
                                    ? "hidden"
                                    : ""
                                  }}`}
                              >
                                <div className="grid grid-cols-4 w-3/4 ">
                                  {filters[key]?.items?.map((filter) => (
                                    <div className="text-left w-full my-1">
                                      <img
                                        src={filter.image}
                                        alt={filter.name}
                                        className={`rounded-full w-8   ${checkFilter(
                                          filters[key].id,
                                          filters[key].name,
                                          filter
                                        )}`}
                                        onClick={() =>
                                          parseFilter(filters[key].id, filter)
                                        }
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : (
                              <div
                                className={`my-5 ${filters[key]?.items.length > 6 &&
                                  "overflow-y-auto h-36 px-1 "
                                  }  ${!filterMobile.includes(
                                    filters[key].attribute_group_id
                                  )
                                    ? "hidden"
                                    : ""
                                  }`}
                              >
                                <div className="grid grid-cols-2	">
                                  {filters[key]?.items?.map((filter) =>
                                    filters[key].name === "DIMENSIONS" ||
                                      1 === 1 ||
                                      filters[key].name === "Size" ? (
                                      <div
                                        className={`border bg-white mr-1.5 my-1 text-dborderblack0 text-d14 ${checkFilter(
                                          filters[key].id,
                                          filters[key].name,
                                          filter
                                        )}`}
                                        onClick={() =>
                                          parseFilter(filters[key].id, filter)
                                        }
                                      >
                                        {filter.name}
                                      </div>
                                    ) : (
                                      <div
                                        className={`text-left w-1/2 hover:underline underline-offset-4 text-dborderblack0 text-d15 pointer-events-auto ${checkFilter(
                                          filters[key].id,
                                          filter.name,
                                          filter
                                        )}`}
                                        onClick={() =>
                                          parseFilter(filters[key].id, filter)
                                        }
                                      >
                                        {filter.name}
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        )
                    )}
                  </div>

                  {filterMobileShow && (
                    <div className="w-screen h-screen	 fixed z-20 top-0 bg-dborderblack3 opacity-50 -ml-2"></div>
                  )}
                  {filterMobileShow && (
                    <div className="flex w-screen z-30 bottom-0 bg-white -ml-2 fixed p-3 font-bold uppercase">
                      <button
                        className="text-center border text-dborderblack5 mx-2 w-1/2 p-3"
                        onClick={() => setFilterMobileShow(false)}
                      >
                        Close
                      </button>
                      <button
                        className="text-center border bg-dborderblack1 hover:bg-dblack2 p-3 mx-1 text-white w-1/2"
                        onClick={() => setFilterMobileShow(false)}
                      >
                        APPLY
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        ) : (
          <NoData />
        )}



      </div>
    </div>
  );
}

export default Category;
