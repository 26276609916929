import React from "react";
import { Link } from "react-router-dom";
import { SlHeart } from "react-icons/sl";
import { useContext, useEffect } from "react";
import buildLink, { path } from "../urls";
import _axios from "../axios";
import { AccountContext } from "../contexts/AccountContext";
import { WishlistContext } from "../contexts/WishlistContext";

function TopWishlist() {
  const [state, dispatch] = useContext(WishlistContext);
  const [accountState] = useContext(AccountContext);

  useEffect(() => {
    dispatch({
      type: "loading",
      payload: true,
    });

    _axios
      .get(buildLink("wishlist", undefined, window.innerWidth))
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: "setProducts",
            payload: response.data.data.products,
          });
          dispatch({
            type: "setTotals",
            payload: response.data.data.totals,
          });
          const ids = response.data.data.products.map((p) => p.product_id);
          dispatch({
            type: "setProductIds",
            payload: ids,
          });
          dispatch({
            type: "loading",
            payload: false,
          });
        }
      });
  }, [state.productsCount, dispatch]);

  return (
    <div>
      <Link 
      to={`${path}/account/wishlist`}
      className="cursor-pointer rounded-full border-dgrey5 p-2 ml-2 border block  relative">
        <div className=" absolute  right-0 top-0  w-5 h-5 shadow-md shadow-dblack1 rounded-full bg-black flex justify-center text-center  text-white"><div className=" w-fit h-fit my-auto font-extrabold text-xs">{state.productsCount}</div></div>
        <div className="">
          <SlHeart className=" text-xl text-dblack2  " />
        </div>
     
      </Link>
    </div>
  );
}

export default TopWishlist;
