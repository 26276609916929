import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsCart3, BsChevronDown, BsChevronUp } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import { CartContext } from "../contexts/CartContext";
import { AccountContext } from "../contexts/AccountContext";
import Slider from "react-slick";
import buildLink, { path } from "../urls";
import _axios from "../axios";
import "../assets/css/index.css";
import { IoMdClose } from "react-icons/io";
import { FaMinus, FaPlus, FaRegPlusSquare, FaTrash, FaTrashAlt } from "react-icons/fa";

function TopCart(props) {
  const [state, dispatch] = useContext(CartContext);
  const [accountState] = useContext(AccountContext);
  const [loadingUpdate,SetLoadingUpdate] = useState(false)
  const location = useLocation();
  const navigate = useNavigate()
  const width = window.innerWidth;

  const upArrow = (
    <div className="absolute z-50 top-0  cursor-pointer">
      <BsChevronUp className="w-8 h-7 text-dgrey6 absolute z-50 top-0 cursor-pointer" />
    </div>
  );

  const downArrow = (
    <div className="absolute z-50 bottom-0 left-1/2 cursor-pointer ">
      <BsChevronDown className="w-8 h-7 text-dgrey6  absolute z-50 bottom-0 cursor-pointer" />
    </div>
  );

  const setting = {
    speed: 100,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    vertical: true,
    arrows: true,
    prevArrow: upArrow,
    nextArrow: downArrow,
  };

  useEffect(() => {
    dispatch({
      type: "loading",
      payload: true,
    });
 
    _axios
      .get(buildLink("cartCount", undefined, window.innerWidth))
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: "setProductsCount",
            payload: response.data.data.nb_of_products,
          });
           
          dispatch({
            type: "loading",
            payload: false,
          });
        } else {
          dispatch({
            type: "setProductsCount",
            payload: 0,
          });
          dispatch({
            type: "loading",
            payload: false,
          });
        }
      });
  }, [dispatch]);

  function getCart() {
    console.log('get cart')
    //if loged in or no
    if ( state?.productsCount > 0) {
      _axios
        .get(buildLink("cart", undefined, window.innerWidth))
        .then((response) => {
          // console.log("response"+response.data.success)

          if (response.data.success) {
            dispatch({
              type: "setProducts",
              payload: response.data.data.products,
            });

            dispatch({
              type: "setProductsCount",
              payload: response.data.data.total_product_count,
            });
            dispatch({
              type: "setTotals",
              payload: response.data.data.totals,
            });
            dispatch({
              type: "loading",
              payload: false,
            });
          }
        });
    }
  }

  function updateQuantity(key, quantity) {
    // alert(quantity)
    const obj = { key, quantity };
    dispatch({
      type: "loading",
      payload: true,
    });
    SetLoadingUpdate(true)
    _axios
      .put(buildLink("cart", undefined, window.innerWidth), obj)
      .then(() => {
        _axios
          .get(buildLink("cart", undefined, window.innerWidth))
          .then((response) => {
            SetLoadingUpdate(false)
            dispatch({
              type: "setProducts",
              payload:
                response.data?.data?.products?.length > 0
                  ? response.data.data.products
                  : [],
            });
            dispatch({
              type: "setTotals",
              payload:
                response.data?.data?.totals?.length > 0
                  ? response.data.data.totals
                  : 0,
            });
            dispatch({
              type: "setProductsCount",
              payload:
                response.data?.data?.total_product_count > 0
                  ? response.data.data.total_product_count
                  : 0,
            });
            dispatch({
              type: "loading",
              payload: false,
            });
            // if (quantity === 0) {
            //   window.location.reload();
            // }
          });
      });
  }

  return (
    <div>
      {/* {!props.cartmenu ? ( */}
        <div className="relative">
   

          <Link 
            onClick={() => {
              getCart();
              dispatch({type:"setShowMenu",payload:true});
            }}
    
      className="cursor-pointer rounded-full border-dgrey5 p-2 border block  relative">
        <div className=" absolute  right-0 top-0  w-5 h-5 shadow-md shadow-dblack1 rounded-full bg-black flex justify-center text-center  text-white"><div className=" w-fit h-fit my-auto font-extrabold text-xs">{state.productsCount}</div></div>
        <div className="">
          <BsCart3 className=" text-xl text-dblack2  " />
        </div>
     
      </Link>























        {state.showmenu && <div onClick={()=>dispatch({type:"setShowMenu",payload:false})} className=" fixed inset-0 bg-dblack1 bg-opacity-60 z-[999] w-full h-full"></div>}
         <div className={` ${state.showmenu ?" right-0 md:right-6":" md:-right-[700px] -right-[200%] "} z-[9999]    duration-500 transition-all max-md:top-0  top-14 bottom-20 w-full h-full  fixed md:w-[30rem] md:h-[40rem]  `}>
          <div className=" relative rounded-lg bg-dgrey8 w-full h-full  overflow-hidden  flex flex-col  justify-between gap-5">
           { loadingUpdate && <div className=" absolute w-full h-full bg-dgrey3 bg-opacity-25 z-50 cursor-wait"></div>}
            <div className=" bg-white  flex flex-row justify-center py-4 px-3 text-center w-full ">
              <h2 className=" font-bold ">My Shopping Cart ({state.productsCount})</h2></div>
            <button onClick={()=>dispatch({type:"setShowMenu",payload:false})} className=" absolute right-4 top-5"><IoMdClose className=" text-xl text-dgrey6"/></button>
            <div className=" px-3 w-full h-full flex flex-col gap-2  overflow-auto ">
            {state.products && state.products.map((product) => (
                        <div
                          className=" flex flex-row justify-start w-full gap-2 relative "
                          key={product?.product_id}
                        >
                          <div>
                            
                            <div className=" relative overflow-hidden rounded-md w-24  h-36 ">
                            <Link
                          to={`${path}/${product?.name
                            .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                            .replace(/\s+/g, "-")
                            .replace("..", "")
                            .replace("/", "-")
                            .replace("---", "-")
                            .replace("--", "-")
                            .replace("/", "")}/p=${product?.product_id}`}
                          className=""
                        >
                              <img className=" h-full w-full object-cover" src={product?.thumb}/>
                              </Link>
                              <div className="hidden max-md:hidden absolute w-full  bg-dwhite1 rounded-md bottom-5 left-0 right-0">
                                <div className=" relative flex flex-row justify-between px-3 py-2 text-sm text-dgrey6 w-full">
                                  <button onClick={() => updateQuantity(product?.cart_id, Number(product?.quantity )+ Number(1))} className=" text-lg ">+</button>
                                  <div  className=" text-[1rem] font-bold text-dblue2 my-auto">{product?.quantity}</div>
                                  <button onClick={() => updateQuantity(product?.cart_id, Number(product?.quantity) - Number(1))} className=" text-lg">-</button>
                                </div>
                              </div>
                            </div>
                          </div>


                          <div className="flex flex-col w-full justify-between gap-2 h-full pt-3 pb-5 text-left">
                            <div className="flex justify-between items-center w-full">
                              <h2 className="font-bold text-start">{product?.name}</h2>
                              
                            </div>
                            <h2 className="font-bold text-dblue2">{product?.unit_price}$</h2>

                            <div className="w-full bg-dbase flex">
                        <button onClick={() => updateQuantity(product?.cart_id, 0)} className=" text-dgrey6 mr-4"><FaTrashAlt/></button>
                        
                         <div className=" max-md:block  border relative  w-32  bg-dwhite1 rounded-md">
                          
                                <div className=" relative flex flex-row justify-between px-2 py-1 text-sm text-dgrey6 w-full">
                                <button onClick={() => updateQuantity(product?.cart_id, Number(product?.quantity) - Number(1))} className=" text-lg">-</button>
                                  <div  className=" text-[1rem] font-bold text-dblue2 my-auto">{product?.quantity}</div>
                                  <button onClick={() => updateQuantity(product?.cart_id, Number(product?.quantity )+ Number(1))} className=" text-lg ">+</button>
                                </div>
                                </div>
                              </div>
                              
                        {/* <div className="w-full bg-dbase flex">
                        <button onClick={() => updateQuantity(product?.cart_id, 0)} className=" text-dgrey6"><FaTrashAlt/></button>
                        </div> */}
                         </div>
                          </div>
            )
            )}
            </div>




            <div className=" h-24 w-full  shadow-lg shadow-black">
              <div className=" flex flex-row justify-between px-9 py-4 ">
                <div className=" flex flex-col justify-center text-left gap-2">
                  <span className=" text-sm font-bold text-dblack1">Grand Total</span>
                  <h2 className=" text-2xl font-extrabold text-dblack1">{state.totals && state.totals.map((item)=>item.code == "total"&& item.text)}</h2>
                </div>


                <button onClick={()=>{
                  navigate(`${path}/cart`)
                }} className=" px-4 py-1 bg-dborderblack1 text-white font-extrabold text-xl"> View Cart </button>
              </div>
            </div>
          </div>
         </div>
     


















        </div>
    </div>
  );
}

export default TopCart;
