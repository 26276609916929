import { useState, useEffect, useContext, useRef } from "react";
import buildLink from "../urls";
import _axios from "../axios";
import { useReactToPrint } from "react-to-print";
import { FaEye } from "react-icons/fa";
import { AiOutlinePrinter } from "react-icons/ai";
import SingleProducts from "../components/SingleProduct";
import SingleProductOnlyImage from "../components/SingleProductOnlyImage";
import Loader from "../components/Loader";
import VerticalNav from "../components/VerticalNav";
import noorders from "../../src/assets/images/no_orders.png"

export default function PosOrders() {
  const [storedArray, setMyArray] = useState();
  const [holdArray, setHoldArray] = useState([]);

  const [showDetails, setShowDetails] = useState();
  const [printShow, setPrinthow] = useState();

  const [details, setDetails] = useState();
  const [holdDetails, setHoldDetails] = useState();

  const [date, setDate] = useState();
  const [totals, setTotals] = useState("");
  const [myFilter, setMyFilter] = useState();
  const [manualResponse, setManualResponse] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [stored, setStored] = useState([]);
  const [ordersArray, setOrdersArray] = useState([]);

  useEffect(() => {
    _axios
      .get(buildLink("orders", undefined, window.innerWidth))
      .then((response) => {
        if (response.data.success) {
          setStored(response.data.data);
        } else {
          console.error("Failed to fetch orders");
        }
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
      });
  }, []);

  // useEffect(() => {
  //   console.log(stored, "this is stored");
  // }, [stored]);

  const dateref = useRef("");

  function handlePrint(id) {
    const url = "/posprint/" + id;

    const windowFeatures =
      " toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=302.36220472441, height=250";

    window.open(url, "_blank", windowFeatures);
  }

  useEffect(() => {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const date = [year, month, day].join("-");
    setDate(date);
    // getOrders(date);
  }, [window.location]);

  // useEffect(() => {
  //   getOrders(date);
  // }, [date, stored]);

  function print() {
    const url = "/ordersprint/";

    const windowFeatures =
      " toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes";

    window.open(url, "", windowFeatures);
  }

  function getOrders(date) {
    var sum = 0;
    if (stored && date !== undefined) {
      _axios
        .get(buildLink("order_details"))
        .then((response) => {
          if (response.data.success) {
            const orders = response.data.data;
            setMyArray(orders);
            orders.forEach((order) => {
              if (date === order?.date_added) {
                order.totals.forEach((item) => {
                  if (item.code === "total") {
                    sum += Number(item?.text?.replace("$", ""));
                  }
                });
              }
            });
          }
          setTotals(sum);
        })
        .catch((error) => {
          console.error("Error fetching orders:", error);
        });
    }
  }

  useEffect(() => {
    // Load the array from local storage on component mount
    const storedHold = localStorage.getItem("hold-order");
    // console.log(JSON.parse(storedHold));
    if (storedHold) {
      setHoldArray(JSON.parse(storedHold));
    }
  }, [window.location]);

  // function detailsShow(value) {
  //   setHoldDetails("");
  //   setShowDetails(value);

  //   setDetails(stored?.filter((item) => item?.order_id?.includes(value)));
  // }

  const detailsShow = (value) => {
    setHoldDetails("");
    setShowDetails(value);
    var sum = 0;
    const orderId = value;
    const url = buildLink("order_details") + `${orderId}`;
    if (stored && date !== undefined) {
      _axios
        .get(url)
        .then((response) => {
          if (response.data.success) {
            const orderDetails = response.data.data;
            if (Array.isArray(orderDetails.products)) {
              setDetails(orderDetails);
            }
            // console.log(details, " this is details")
          }
          setTotals(sum);
        })
        .catch((error) => {
          console.error("Error fetching orders:", error);
        });
    }
  };

  function detailsHoldShow(value) {
    setDetails("");
    setShowDetails(value);
    setHoldDetails(holdArray[value]);
  }
  function filterDate(e) {
    setMyFilter("");
    // console.log(dateref.current.value)
    var d = new Date(e.target.value),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const date = [year, month, day].join("-");
    // console.log(date);
    setDate(date);

    // console.log("storedArray");
    // console.log(storedArray);
    // console.log("myf");
    // console.log(myFilter);
    // // calculateSum(date)
  }
  function filterID(e) {
    e.target.value != ""
      ? setMyArray(
          storedArray?.filter((item) =>
            item?.order_id?.includes(e.target.value)
          )
        )
      : getOrders();
  }
  function filterName(e) {
    e.target.value != ""
      ? setMyArray(
          storedArray?.filter(
            (item) =>
              item?.includes(e.target.value) ||
              item?.lastname?.includes(e.target.value)
          )
        )
      : setMyArray(JSON.parse(localStorage.getItem("orders")));
  }

  function sync() {
    _axios
      .post(buildLink("manual", undefined, window.innerWidth), holdDetails)
      .then((response) => {
        setManualResponse(response?.data?.data);

        if (response?.data?.success === false) {
          // console.log(response?.data);
          setError(response?.data?.errors);

          if (
            response?.data?.errors.length === 1 &&
            response?.data.message === "OUT OF STOCK"
          ) {
            return;
          }
        } else {
          setError("");
          paymentForm(true, "cod");
          setManualResponse(response?.data?.data);
        }
      });
  }

  function paymentForm(confirm, p_m) {
    // setLoading(true);
    _axios
      .post(buildLink("payment_form"), { payment_method: p_m })
      .then((response) => {
        const data = response.data;
        try {
          document.getElementById("simp-id").outerHTML = "";
        } catch (e) {}
        const script = document.createElement("script");
        script.src = "https://www.simplify.com/commerce/simplify.pay.js";
        script.async = false;
        script.id = "simp-id";
        document.body.appendChild(script);

        if (data.success) {
          // setId(data.order_id);

          if (p_m === "cod" && confirm) {
            // if (Object.keys(manualErrors.current).length === 0) {
            confirmOrder(data.confirm_url, data.success_url);
            // }
          }
        }
      });
  }
  function confirmOrder(c_url, s_url) {
    _axios.post(c_url).then((response) => {
      const data = response.data;
      if (data.success) {
        successOrder(s_url);
        setSuccess(true);
      }
    });
  }

  function successOrder(url) {
    _axios.get(url).then((response) => {
      const data = response.data;
      if (data.success) {
        // addToLocalStorage(data?.data?.orderDetails?.order_id, showDetails);
        // console.log(data.data);
        // console.log(manualResponse);
        // handlePrint();
        // history.push("/pos");
      }
    });
  }

  // useEffect(() => {
  //   // Save the array to local storage whenever it changes
  //   if(stored?.length > 0)
  //   localStorage.setItem("orders", JSON.stringify(stored));
  // }, [stored]);

  useEffect(() => {
    // Save the array to local storage whenever it changes
    localStorage.setItem("hold-order", JSON.stringify(holdArray));
  }, [holdArray]);

  return (
    <div className={`bg-dgrey10 pt-5 ${window.innerWidth < 650 ? "px-4" : "px-32"}`}>
  {!stored ? (
    <Loader />
  ) : stored?.orders?.length === 0 && holdArray?.length === 0 ? (
    <div className="flex mt-2 gap-5">
    {window.innerWidth > 650 && (
      <div className="w-1/2">
        <VerticalNav />
      </div>
    )}
    <div className="flex flex-col justify-center items-center w-full">
      <img
        src={noorders}
        alt="No orders available"
        className="w-1/2 h-1/2 object-contain"
      />
      <p className="uppercase font-bold text-blue-500 ">No Orders Yet</p>
    </div>
  </div>
  ) : (
    <>
      {showDetails && (
        <div
          id="overlay"
          className="fixed z-40 w-screen h-screen inset-0 bg-dblack1 bg-opacity-60"
        ></div>
      )}

{(showDetails  || showDetails === 0 )&& (
        <div class="fixed inset-0 z-50 overflow-y-auto overflow-auto">
  <div class="flex items-center justify-center min-h-screen p-4">
    <div class="relative w-full max-w-md md:max-w-lg lg:max-w-xl mx-auto bg-white rounded-lg shadow-lg">
      <div class="flex items-center justify-between p-5 border-b border-gray-200">
        <h5 class="text-xl font-medium text-gray-900" id="exampleModalLabel">
                  Order Details
                </h5>

                {error?.length > 0 &&
                  error?.map(
                    (err) =>
                      (err?.errorCode === "stock" ||
                        err?.errorCode === "option") && (
                        <p className="  m-1 text-dred2 text-d22">
                          {error[0]?.errorMsg}
                        </p>
                      )
                  )}
                <button onClick={() => setShowDetails(!showDetails)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-6 w-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div>
                <table class="min-w-full text-left text-sm font-light">
                  <thead class="border font-medium border-DarkGrey">
                    <tr>
                      <th scope="col" class="px-2 py-4">
                        #
                      </th>
                      <th scope="col" class="py-4">
                        product
                      </th>
                      <th scope="col" class="pr-4 py-4">
                        sku
                      </th>
                      <th scope="col">price</th>
                      <th scope="col" class=" py-4">
                        QTY
                      </th>

                      <th scope="col" class="py-4">
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  {details &&
                    details.products?.map((product, index) => (
                      <tr key={product.product_id} className="border border-y-dgrey14">
                        <td className="whitespace-nowrap px-4 py-2 font-medium">
                          {index + 1}
                        </td>
                        {/* <img className="whitespace-nowrap py-2 truncate-multiline w-20 h-20" src={product?.image}></img> */}
                       <td className="w-20 h-20"> <SingleProductOnlyImage item={product} /></td>   
                        <td className="whitespace-nowrap py-2">{product?.sku}</td>
                        <td className="whitespace-nowrap py-2">{product?.price || 'N/A'}</td>
                        <td className="whitespace-nowrap py-2">{product?.quantity || 'N/A'}</td>
                        <td className="whitespace-nowrap py-2">{product?.total || 'N/A'}</td>
                      </tr>
                    ))}


                    {holdDetails &&
                      holdDetails?.order_product?.map((order, key) => (
                        <tr class="border border-DarkGrey ">
                          <td class="whitespace-nowrap px-4 py-2 font-medium">
                            {key + 1}
                          </td>
                          <td class="whitespace-nowrap py-2">{order?.name} - {  order?.order_option[0] && (order?.order_option[0]?.name +":"+  order?.order_option[0]?.value)}</td>
                          {/* <td class="whitespace-nowrap px-6 py-4">${order.sub_total}</td> */}
                          <td class="whitespace-nowrap  py-2">{order?.sku}</td>
                          <td class="whitespace-nowrap  py-2">
                            {order?.model} 
                          </td>
                          <td class="whitespace-nowrap  py-2">
                            {order?.unit_price}
                          </td>
                          <td class="whitespace-nowra py-2">
                            {order?.quantity}
                          </td>

                          <td class="whitespace-nowrap  py-2">
                            {order?.price}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  {holdDetails && (
                    <tfoot>
                      <tr class="border border-DarkGrey ">
                        <td
                          class="whitespace-nowrap  py-2 pr-8 font-medium text-right"
                          colspan="5"
                        >
                          Sub Total
                        </td>
                        <td class="whitespace-nowrap  py-2">
                          {holdDetails?.sub_total}
                        </td>
                      </tr>
                      {holdDetails?.modification_amount && (
                        <tr class="border border-DarkGrey ">
                          <td
                            class="whitespace-nowrap  py-2 pr-8 font-medium text-right"
                            colspan="5"
                          >
                            {holdDetails?.modification_type}
                          </td>
                          <td class="whitespace-nowrap  py-2">
                            {holdDetails?.modification_amount}
                          </td>
                        </tr>
                      )}

                      {holdDetails.coupon && (
                        <tr class="border border-DarkGrey ">
                          <td
                            class="whitespace-nowrap  py-2 pr-8 font-medium text-right"
                            colspan="5"
                          >
                            Coupon
                          </td>
                          <td class="whitespace-nowrap  py-2">
                            {holdDetails?.coupon}
                          </td>
                        </tr>
                      )}
                      <tr class="border border-DarkGrey ">
                        <td
                          class="whitespace-nowrap  py-2 pr-8 font-medium text-right"
                          colspan="5"
                        >
                          Total
                        </td>
                        <td class="whitespace-nowrap  py-2">
                          {holdDetails?.order_total}
                        </td>
                      </tr>
                    </tfoot>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex w-full flex-col p-5">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full text-left font-light stripe hover">
                <thead className="border-b font-medium border-DarkGrey">
                <tr>
                    <th scope="col" class="px-6 py-4  w-1/12">
                      Order ID
                    </th>
                    <th scope="col" class="px-6 py-4 w-2/12">
                      customer name
                    </th>
                    <th scope="col" class="px-6 py-4 w-2/12">
                      number of products
                    </th>
                    <th scope="col" class="px-6 py-4">
                      status
                    </th>
                    <th scope="col" class="px-6 py-4">
                      date
                    </th>
                    <th scope="col" class="px-6 py-4 w-1/12">
                      total
                    </th>
                    <th scope="col" class="px-6 py-4">
                      Order Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {stored?.orders?.length > 0 && 
                    stored?.orders?.map((order) => (
                      <tr key={order.order_id} className="border border-dblackOverlay">
                        <td class="whitespace-nowrap px-6 py-2 font-medium">
                            {order?.order_id}
                          </td>
                          <td class="whitespace-nowrap px-6 py-2 font-medium">
                            {order?.name}
                          </td>
                          <td class="whitespace-nowrap px-6 py-2 font-medium">
                            {order?.products}
                          </td>
                          <td class="whitespace-nowrap px-6 py-4 font-medium">
                            {order?.status_group}
                          </td>

                          <td class="whitespace-nowrap px-6 py-4 font-medium">
                            {order?.date_added}
                          </td>
                          <td class="whitespace-nowrap px-6 py-4 font-medium">
                            {order?.total}
                          </td>
                          <td class="whitespace-nowrap md:px-12 max-sm:px-6 py-4 flex">
                            <button
                              class="bg-transparent hover:bg-dblue text-blue-700 font-semibold hover:text-white py-3 px-3 border border-blue-500 hover:border-transparent rounded-full"
                              onClick={() => {
                                detailsShow(order?.order_id);
                              }}
                            >
                              <FaEye />
                            </button>

                            {/* <button
                              href={"/posprint/" + order?.order_id}
                              // target="_blank"
                              class="ml-2 bg-transparent hover:bg-dblue text-blue-700 font-semibold hover:text-white py-3 px-3 border border-blue-500 hover:border-transparent rounded-full"
                              onClick={() => handlePrint(order?.order_id)}
                            >
                              <AiOutlinePrinter />
                            </button> */}
                          </td>
                        </tr>
                      ))
                  }

                  {holdArray?.length > 0 &&
                    holdArray.map((order, key) => (
                      <tr key={key} className="border border-DarkGrey bg-dmenusep bg-opacity-40">
                        <td class="whitespace-nowrap px-6 py-2 font-medium ">
                            hold
                          </td>
                          <td class="whitespace-nowrap px-6 py-2 font-medium">
                            {order?.firstname + " "}{" "}
                            {order?.lastname !== "Local Customer" &&
                              order?.lastname}
                          </td>
                          <td class="whitespace-nowrap px-6 py-2 font-medium">
                            {order?.telephone}
                          </td>
                          <td class="whitespace-nowrap px-6 py-4">
                            {order.sub_total}
                          </td>

                          <td class="whitespace-nowrap px-6 py-4">
                            {order.modification_amount}
                          </td>

                          <td class="whitespace-nowrap px-6 py-4">
                            {order.coupon}
                          </td>
                          <td class="whitespace-nowrap px-6 py-4">
                            {order.order_total}
                          </td>
                          <td class="whitespace-nowrap px-6 py-2 flex">
                            <button
                              class="bg-transparent hover:bg-dblue text-blue-700 font-semibold hover:text-white py-3 px-3 border border-blue-500 hover:border-transparent rounded-full"
                              onClick={() => detailsHoldShow(key)}
                            >
                              <FaEye />
                            </button>

                            <button
                              href={"/posprinthold/" + key}
                              // target="_blank"
                              class="ml-2 bg-transparent hover:bg-dblue text-blue-700 font-semibold hover:text-white py-3 px-3 border border-blue-500 hover:border-transparent rounded-full"
                              onClick={() => handlePrint(key)}
                            >
                              <AiOutlinePrinter />
                            </button>
                          </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div className="pr-7 fixed bottom-0 px-12 py-6 w-full text-d22 font-semibold bg-white border-t border-DarkGrey">
            <div className="flex justify-between">
              <div></div>
              <div>Totals ${totals > 0 ? totals : 0}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )}
</div>

  );
}
