import { hover } from "@testing-library/user-event/dist/hover";
import { React, useState, useEffect } from "react";
import Slider from "react-slick";
import SmallArrows from "./SmallArrows";
import product_image from "../assets/images/product.png";
import ProductZoomModal from "./ProductZoomModal";
import CustomArrows from "./CustomArrows";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; // Import arrow icons

function ProductNewZoom(props) {
  // Move to next image
  const nextImage = () => {
    const newIndex = (currentSlide + 1) % images.length;
    setActiveImage(images[newIndex]);
    setCurrentSlide(newIndex);
  };

  // Move to previous image
  const prevImage = () => {
    const newIndex = (currentSlide - 1 + images.length) % images.length;
    setActiveImage(images[newIndex]);
    setCurrentSlide(newIndex);
  };

  const [activeImage, setActiveImage] = useState("");
  const [images, setImages] = useState(props.images);
  const [hoverZoom, setHoverZoom] = useState(false);
  const [lensClass, setLensClass] = useState("hidden");
  const [showModal, setShowModal] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const productSetting = {
    speed: 200,
    slidesToShow: 4.5,
    slidesToScroll: 4.5,
    infinite: false,
    prevArrow: <CustomArrows direction={"l"} />,
    nextArrow: <CustomArrows direction={"r"} />,
  };

  const productMobile = {
    dots: false,
    speed: 200,
    slidesToShow: 2.5,
    swipeToSlide: true,
    infinite: false,
    arrows: false,
    lazyLoad: true,
  };
  const width = window.innerWidth;

  useEffect(() => {
    setImages(props.images);

    setActiveImage(images[0]);

    props?.images?.map((i) => {
      if (i.product_option_value_id === props.activeOption) {
        setActiveImage(i);
      }
    });

    return () => {
      setActiveImage({});
      setImages([]);
    };
  }, [props.activeOption, props.images]);

  useEffect(() => {
    if (hoverZoom) {
      imageZoom("myimage", "myresult");
    }
  }, [hoverZoom]);

  const setting = {
    dots: false,
    infinite: false,
    speed: 100,
    slidesToShow: width > 840 ? 6 : 4,
    slidesToScroll: 5,
    autoplay: false,
    vertical: false,
  };
  const mobileSetting = {
    dots: false,
    infinite: false,
    speed: 100,
    slidesToShow: 3,
    slidesToScroll: 2,
    swipeToSlide: true,
    autoplay: false,
  };

  function imageZoom(imgID, resultID) {
    var img, lens, result, cx, cy;
    img = document.getElementById(imgID);
    result = document.getElementById(resultID);
    /*create lens:*/
    lens = document.createElement("DIV");
    lens.setAttribute("class", "img-zoom-lens " + lensClass);
    /*insert lens:*/
    img.parentElement.insertBefore(lens, img);
    /*calculate the ratio between result DIV and lens:*/
    cx = result.offsetWidth / (lens.offsetWidth * 1.5);
    cy = result.offsetHeight / (lens.offsetHeight * 1.5);
    /*set background properties for the result DIV:*/
    result.style.backgroundImage = "url('" + img.src + "')";
    result.style.backgroundSize =
      img.width * cx + "px " + img.height * cy + "px";
    /*execute a function when someone moves the cursor over the image, or the lens:*/
    lens.addEventListener("mousemove", moveLens);
    img.addEventListener("mousemove", moveLens);
    /*and also for touch screens:*/
    lens.addEventListener("touchmove", moveLens);
    img.addEventListener("touchmove", moveLens);
    function moveLens(e) {
      var pos, x, y;
      /*prevent any other actions that may occur when moving over the imagee:*/
      e.preventDefault();
      /*get the cursor's x and y positions:*/
      pos = getCursorPos(e);
      /*calculate the position of the lenss:*/
      x = pos.x - lens.offsetWidth / (10 * 1.5);
      y = pos.y - lens.offsetHeight / (10 * 1.5);
      /*prevent the lens from being positioned outside the image:*/
      if (x < 0) {
        x = 0;
      }
      if (y < 0) {
        y = 0;
      }
      if (x > img.width - lens.offsetWidth) {
        x = img.width - lens.offsetWidth;
      }

      if (y > img.height - lens.offsetHeight) {
        y = img.height - lens.offsetHeight;
      }

      // prevent the lens from being positioned outside the image:
      x = Math.max(Math.min(x / 1.5, img.width - lens.offsetWidth), 0);
      y = Math.max(Math.min(y / 1.5, img.height - lens.offsetHeight), 0);

      /*set the position of the lens:*/
      lens.style.left = x + "px";
      lens.style.top = y + "px";
      /*display what the lens "sees":*/
      result.style.backgroundPosition = "-" + x * cx + "px -" + y * cy + "px";
    }
    function getCursorPos(e) {
      var a,
        x = 0,
        y = 0;
      e = e || window.event;
      /*get the x and y positions of the imagee:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x and y coordinates, relative to the image:*/
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }
  }

  function closeModal() {
    setShowModal(false);
    props.hideFixedCartMenu(false);
    const htmlElement = document.querySelector("html");
    htmlElement.classList.remove("popup-open");
    const bodyElement = document.querySelector("body");
    bodyElement.classList.remove("popup-open");
  }

  function changeImage(imgSrc) {
    var image = document.getElementById("myimage");
    image.style.opacity = "0";
    setTimeout(function () {
      setActiveImage(imgSrc);
      const selectedImgIndex = images.findIndex(
        (item) => item.popup === imgSrc.popup && item.thumb === imgSrc.thumb
      );
      setCurrentSlide(selectedImgIndex);
      image.addEventListener("load", () => {
        image.style.opacity = "1"; // set the opacity to 1 after a short delay
      });
    }, 250); // the delay in milliseconds, should match the duration of the CSS transition
  }

  function htmlOverflow() {
    const htmlElement = document.querySelector("html");
    const bodyElement = document.querySelector("body");

    // Add a CSS class to remove the overflow-y
    htmlElement.classList.add("popup-open");
    bodyElement.classList.add("popup-open");
  }

  return (
    <div>
      {showModal && (
        <ProductZoomModal
          selectedImage={activeImage}
          images={images}
          productData={props.productData}
          currentSlideIndex={currentSlide}
          closeModal={closeModal}
          hideFixedCartMenu={props.hideFixedCartMenu}
        />
      )}

      {/* my code */}
      <div className="relative">
        {images.length > 0 && (
          <div className="flex flex-col">
            <div className=" w-full md:w-12/12 relative flex items-center lg:px-8">
              <div
                onMouseEnter={() => {
                  setHoverZoom(true);
                  setLensClass("");
                }}
                onMouseLeave={() => {
                  setHoverZoom(false);
                  setLensClass("hidden");
                }}
                className={"hover:cursor-zoom-in   "}
              >
                {activeImage["popup"]?.length > 0 ? (
                  <div
                    onClick={() => {
                      htmlOverflow();
                      setShowModal(true);
                      props.hideFixedCartMenu(true);
                    }}
                    className="min-h-260px lg:min-h-430px relative"
                  >
                    <img
                      id="myimage"
                      src={activeImage["popup"]}
                      alt=""
                      className="rounded-lg myimage-product-zoom "
                    />
                    <div className="absolute  top-0 left-0 bg-red-500 text-white rounded-full py-4 px-2 text-xl font-bold w-16 h-16">
                      {props.productData.saving + "%"}
                    </div>
                  </div>
                ) : (
                  <img src={product_image} alt="" className="rounded-lg  " />
                )}
              </div>
              <div>
                <div
                  id="myresult"
                  className={`img-zoom-result absolute z-10 rounded-lg top-0 ml-4      ${
                    hoverZoom && width > 650 ? "" : "hidden"
                  }`}
                ></div>

                {/* Left Arrow */}
                <button
                  className="absolute top-1/2 left-10 rounded-full transform -translate-y-1/2 w-7 h-7 bg-gray-200 bg-opacity-50 hover:bg-opacity-100 text-dborderblack2 flex items-center justify-center opacity-50 hover:opacity-100"
                  onClick={prevImage}
                >
                  <FaArrowLeft className="text-dborderblack1 text-lg" />
                </button>

                {/* Right Arrow */}
                <button
                  className="absolute top-1/2 right-10 rounded-full transform -translate-y-1/2 w-7 h-7 bg-gray-200 bg-opacity-50 hover:bg-opacity-100 text-dborderblack2 flex items-center justify-center opacity-50 hover:opacity-100"
                  onClick={nextImage}
                >
                  <FaArrowRight className="text-dborderblack1 text-lg" />
                </button>
              </div>
            </div>

            {/* selector div */}
            <div className="w-full thumbs-slider mt-2 ">
              {width > 650 ? (
                <Slider {...setting}>
                  {images?.map((i) => (
                    <div
                      key={i["thumb"]}
                      className={` flex justify-center mt-2 mr-4 cursor-pointer ${
                        activeImage["popup"] === i["popup"]
                          ? "border-3 border-dborderblack1"
                          : ""
                      } outline-none`}
                    >
                      <img
                        src={i["thumb"]}
                        alt=""
                        onClick={() => changeImage(i)}
                        className={`cursor-pointer `}
                      />

                    </div>
                  ))}
                </Slider>
              ) : (
                <div className="flex justify-center">
                  <Slider {...mobileSetting}>
                    {images?.map((i) => (
                      <div
                        key={i["thumb"]}
                        className={`flex justify-center mt-2 mr-4 cursor-pointer ${
                          activeImage["popup"] === i["popup"]
                            ? "border-3 border-dborderblack1"
                            : ""
                        } outline-none`}
                      >
                        <img
                          src={i["thumb"]}
                          alt=""
                          onClick={() => changeImage(i)}
                          className="cursor-pointer"
                        />
                        
                      </div>
                    ))}
                  </Slider>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductNewZoom;
