import React from "react";
import { Link } from "react-router-dom";
import { path } from "../urls";
import _axios from "../axios";
import product_image from "../assets/images/singleProduct.png";
import ImageFilter from "react-image-filter/lib/ImageFilter";

export default function SingleProductOnlyImage(props) {
  return (
    <>
      <Link
        to={`${path}/${props.item.name
          .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
          .replace(/\s+/g, "-")
          .replace("..", "")
          .replace("/", "-")
          .replace("---", "-")
          .replace("--", "-")
          .replace("/", "")}/p=${props.item.product_id}`}
        className="relative translate-y-0 hover:-translate-y-1 shadow-sm rounded-md pb-2 hover:shadow-lg duration-300 transition-all bg-white shadow-dgrey4 block  group w-full"
        onClickCapture={props.click}
      >
        <div className="relative  m-2.5">
          <div className=" rounded-lg  relative overflow-hidden">
            {props.item.quantity === "0" && (
              <div
                className={
                  window.innerWidth > 650
                    ? "absolute z-20 text-red-700 text-d18 font-semibold w-full text-center  bottom-0"
                    : "absolute z-20 text-red-700  w-full text-center  bottom-0 "
                }
              >
                Out Of Stock
              </div>
            )}

            {props.item.quantity === "0" ? (
              <ImageFilter
                image={props.item.image}
                filter={"duotone"}
                colorOne={[96, 96, 96]}
                colorTwo={[255, 255, 255]}
              />
            ) : (
              <img
                loading="lazy"
                className="object-cover w-full h-full"
                src={props.item.image}
                placeholderSrc={product_image}
              />
            )}
          </div>
        </div>
      </Link>
    </>
  );
}
