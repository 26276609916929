import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import _axios from "../axios";
import buildLink, { path } from "../urls";
import { MdDoubleArrow } from "react-icons/md";
import { AccountContext } from "../contexts/AccountContext";
function DesktopMenu() {
  const [selectedTopCategory, setSelectedTopCategory] = useState({});
  const [menuCategories2, setMenuCategories2] = useState([]);
  const [selectedMenuCategory2, setSelectedMenuCategory2] = useState();
  const [viewMenuCategories2, setViewMenuCategories2] = useState(true);
  const [headerMenu, setHeaderMenu] = useState();
  const width = window.innerWidth;
  const [state, dispatch] = useContext(AccountContext);

  // console.log(path);
  //

  useEffect(() => {
    if (width > 650) {
      _axios
        .get(buildLink("all_categories", undefined, window.innerWidth))
        .then((response) => {
          try {
            const data = response.data.data;

            setSelectedTopCategory(data[0]);
          } catch (error) {}
        });
      _axios
        .get(buildLink("headerv2", undefined, window.innerWidth))
        .then((response) => {
          const data = response?.data;
          setMenuCategories2(data.data);
          setSelectedMenuCategory2(data[0]);
          setHeaderMenu(data.header_settings);
        });
    }
  }, []);

  useEffect(() => {
    if (state.viewProfileMenu) {
      setViewMenuCategories2(false);
    }
  }, [state.viewProfileMenu]);
  return (
    <div className="hidden lg:block relative">
      <nav className="header-menu border-b  border-dgrey5 mt-6 h-17 xl:h-7.5">
        <div className="">
          <div className="flex flex-col xl:flex-row justify-center items-center mx-auto">
            <ul className="navigation relative text-center  flex  justify-center">
              {/* {headerMenu &&
                headerMenu.length > 0 &&
                headerMenu
                  .filter(
                    (setting) => setting.key === "new_arrivals" && setting.value
                  )
                  .map((setting, index) => (
                    <div
                      key={index}
                      className="px-4 hover:text-dbase text-dgreen text-sm text-dblack cursor-pointer font-medium hover:font-extrabold  hover:bg-dgrey3"
                    >
                      <Link to="/new_arrivals/c=437">{setting.title}</Link>
                    </div>
                  ))} */}
              <div
                className="flex flex-row"
                onMouseEnter={() => setViewMenuCategories2(true)}
                onMouseLeave={() => setViewMenuCategories2(false)}
              >
                {menuCategories2?.length > 0 &&
                  menuCategories2.map((category) => (
                    <li className="" key={Math.random()}>
                      <Link
                        to={`${path}/${category["title"].title
                          .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                          .replace(/\s+/g, "-")
                          .replace(/'/g, "")}/c=${
                          category["title"].mobile_type_id
                        }`}
                        className={`${
                          viewMenuCategories2 &&
                          selectedMenuCategory2 == category
                            ? "bg-dgrey3 font-extrabold"
                            : ""
                        } ${
                          category["title"].mobile_type_id === 437
                            ? "text-dgreen"
                            : category["title"].mobile_type_id === 477
                            ? "text-red-600"
                            : ""
                        } bg-transparent text-dblack2 text-d15 p-2 transition-all font-semibold hover:font-extrabold hover:bg-dgrey3 uppercase whitespace-nowrap`}
                        dangerouslySetInnerHTML={{
                          __html: category["title"].title,
                        }}
                        onMouseEnter={() => {
                          if (category.title.has_child == 1) {
                            setSelectedMenuCategory2(category);
                            setViewMenuCategories2(true);
                          } else {
                            setSelectedMenuCategory2(null);
                            setViewMenuCategories2(false);
                          }
                        }}
                      ></Link>
                    </li>
                  ))}
              </div>
            </ul>

            {/* <div className="inline-block mt-4 xl:mt-0 ml-1">
              <Link
                to={`/August-Hot-Sale-/c=463`}
                className="bg-dred1 text-dwhite1 text-d12 p-2.5 whitespace-nowrap"
              >
                Hot Sale
              </Link>
            </div> */}
          </div>
        </div>
      </nav>
      {/* Menu Categories */}

      <div
        className={`navigation-submenu absolute duration-500  shadow-md overflow-hidden bg-dgrey3 text-dblack1 z-50 text-left 
  w-[70%] ml-9  md:left-[9.5rem] transform -translate-x-1/2 sm:translate-x-0 top-full container`}
        onMouseEnter={() => {
          setViewMenuCategories2(true);
        }}
        onMouseLeave={() => {
          setViewMenuCategories2(false);
        }}
      >
        {viewMenuCategories2 && selectedMenuCategory2 && (
          <div className="flex overflow-y-auto h-full">
            <div className="relative pt-7 bg-dgrey3 w-1/2">
              <div className="navigation-submenu-item  left-3 top-7 pb-7 pr-2.5 pl-3.5  border-r border-dgrey5 ">
                <div className=" text-lg font-bold mb-1.5">
                  <Link
                    to={`${path}/${selectedMenuCategory2["top-category"]?.name
                      ?.replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                      ?.replace(/\s+/g, "-")}/c=${
                      selectedMenuCategory2["top-category"].category_id
                    }`}
                    className="uppercase"
                    dangerouslySetInnerHTML={{
                      __html: selectedMenuCategory2["title"].title,
                    }}
                  ></Link>
                </div>
                <div className="inline-block ">
                  {selectedMenuCategory2["sub-categories-level1"]?.map(
                    (subcategory, key) => (
                      <ul>
                        <li key={subcategory.category_id} className="">
                          <div className="flex pr-5">
                            <Link
                              to={`${path}/${subcategory.name
                                .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                                .replace(/\s+/g, "-")}/c=${
                                subcategory.category_id
                              }`}
                              className="cursor-pointer hover:underline text-sm capitalize font-bold "
                              dangerouslySetInnerHTML={{
                                __html: subcategory.name,
                              }}
                              key={subcategory.category_id}
                            ></Link>
                          </div>
                        </li>
                      </ul>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className=" flex py-7 px-3  w-1/2 justify-start">
              <div className="dropdown-extra-content ">
                {selectedMenuCategory2["partitions"]?.map(
                  (category) =>
                    category?.banners > 0 &&
                    category?.banners?.map((banner) => (
                      <Link
                        classname="grid grid-flow-col "
                        key={banner.banner_id}
                      >
                        <img
                          src={`${
                            window.config["site-url"] +
                            "/image/" +
                            banner?.image
                          }`}
                          alt={selectedMenuCategory2["top-category"]?.name}
                        />
                      </Link>
                    ))
                )}
                {selectedMenuCategory2["top-category"].top_images &&
                  selectedMenuCategory2["top-category"]?.top_images?.map(
                    (image) => (
                      <div classname=" mx-5" key={image.src}>
                        <img
                          className="w-full"
                          src={`${
                            window.config["site-url"] + "/image/" + image?.src
                          }`}
                          alt={image.alt}
                        />
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DesktopMenu;
