import React, { useState, useContext, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AccountContext } from "../contexts/AccountContext";
import VerticalNav from "../components/VerticalNav";
import _axios from "../axios";
import buildLink from "../urls";
import { FaDigitalTachograph } from "react-icons/fa";
import PhoneHandler from "../components/PhoneHandler";
import ChangePassword from "./ChangePassword";
import Loader from "../components/Loader";
import AccountHeader from "../components/AccountHeader";
export default function Profile() {
  const [stateAccount, dispatchAccount] = useContext(AccountContext);
  const [data, setData] = useState();
  const [error, setError] = useState();

  const [isvalid, setIsValid] = useState();

  const fnameRef = useRef("");
  const lnameRef = useRef("");
  const phoneRef = useRef("");

  const navigate = useNavigate();

  console.log(stateAccount.loged);

  useEffect(() => {
    _axios
      .get(buildLink("get_account", undefined, window.innerWidth))
      .then((response) => {
        if (!response.data.success) {
          dispatchAccount({ type: "setLoading", payload: false });
          if (!stateAccount.loading && !stateAccount.loged) {
            navigate("/");
          }
        } else {
          setData(response?.data?.data);
          dispatchAccount({ type: "setEmail", payload: response?.data?.data?.email });
        }
      });
  }, [window.location.href]);

  const phoneHanlder = (childData, isValid) => {
    if (isValid === true) {
      phoneRef.current.value = childData;
    } else {
      phoneRef.current.value = childData;
    }

    setIsValid(isValid);
  };

  function saveDetails(event) {
    event.preventDefault();

    var obj = {
      firstname: fnameRef.current.value,
      lastname: lnameRef.current.value,
      telephone: phoneRef.current.value,
      email: data.email,
    };

    _axios
      .put(buildLink("save_account", undefined, window.innerWidth), obj)
      .then((response) => {
        if (!response.data.success) {
          setError(response?.data?.errors[0]);
        } else {
          setError("");
        }
      });
  }

  function disabledAccount(e) {
    e.preventDefault();
    _axios
      .post(buildLink("disabledAccount", undefined, window.innerWidth))
      .then((response) => {
        if (response.data.success) {
          logout();
          window.location.href = "/";
        }
      });
  }

  function logout() {
    _axios.post(buildLink("logout")).then(() => {});
  }


  return (
    <div className={`bg-dgrey10 pt-5 ${window.innerWidth < 650 ? "px-4" : "px-32"}`}>
  {!data ? (
    <Loader />
  ) : (
    <>
      {/* <AccountHeader stateAccount={stateAccount} /> */}
      <div className="flex mt-2 gap-5">
        {window.innerWidth > 650 && (
          <div className="w-1/2">
            <VerticalNav />
          </div>
        )}
        <div className="w-full">
          <form onSubmit={(e) => saveDetails(e)} className="p-6 text-d12 bg-white rounded-lg shadow-md my-3">
            <div className="flex flex-col md:flex-row gap-6">
              <div className="profile-div md:w-1/2">
                <div className="text-d20 font-semibold capitalize py-2">My Profile</div>
                
                <div className="mb-4">
                  <label className="text-d14 block">First Name</label>
                  <input
                    type="text"
                    className={`bg-white text-left p-2 border rounded-md w-full md:w-3/5`}
                    ref={fnameRef}
                    defaultValue={data?.firstname}
                  />
                  {error?.errorCode === "firstname" && (
                    <div className="text-dred2 mt-1">{error.errorMsg}</div>
                  )}
                </div>

                <div className="mb-4">
                  <label className="text-d14 block">Last Name</label>
                  <input
                    className={`bg-white text-left p-2 border rounded-md w-full md:w-3/5`}
                    ref={lnameRef}
                    defaultValue={data?.lastname}
                  />
                  {error?.errorCode === "lastname" && (
                    <div className="text-dred2 mt-1">{error.errorMsg}</div>
                  )}
                </div>

                <div className="mb-4">
                  <label className="text-d14 block">Telephone</label>
                  <PhoneHandler
                    phone={phoneRef}
                    nb={data?.telephone ? data?.telephone : ""}
                    phoneHanlder={phoneHanlder}
                    className={`w-full md:w-3/5`}
                  />
                  {error?.errorCode === "telephone" && (
                    <div className="text-dred2 mt-1">{error.errorMsg}</div>
                  )}
                </div>

                <div className="text-center md:text-left mt-6">
                  <button className="bg-dborderblack1 text-white px-6 py-2 rounded-md text-d15">
                    SAVE
                  </button>
                </div>
              </div>

              <div className="delete-account-div md:w-1/2 text-center md:border-l flex flex-col justify-center items-center p-6 rounded-lg">
                <div className="text-d20 font-semibold capitalize mb-3">Account Deletion</div>
                <button
                  className="bg-dborderblack1 text-white px-6 py-2 rounded-md text-d14"
                  onClick={(e) => disabledAccount(e)}
                >
                  DELETE MY ACCOUNT
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )}
</div>

  );
}
