import { useEffect, useState, useContext, useRef } from "react";
import { AccountContext } from "../contexts/AccountContext";
import buildLink, { path } from "../urls";
import _axios from "../axios";
import { Link, useNavigate } from "react-router-dom";
import { FiUser } from "react-icons/fi";
import Cookies from "js-cookie";
import { CiUser } from "react-icons/ci";

function TopAccount() {
  const [state, dispatch] = useContext(AccountContext);
  const [showmenu, setShowmenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const width = window.innerWidth;

  useEffect(() => {
    const initializeAdmin = async () => {
      dispatch({ type: "setAdminLoading", payload: true });

      let adminToken = Cookies.get("ATDetails");
      if (
        window.location.host === "localhost:3000" ||
        window.location.host === "localhost:3001"
      ) {
        adminToken = "eab4e66ebc6f424bf03d9b4c712a74ce";
      }

      if (adminToken !== undefined) {
        dispatch({ type: "setAdminToken", payload: adminToken });
        dispatch({ type: "setAdmin", payload: true });
      } else {
        dispatch({ type: "setAdmin", payload: false });
      }
      dispatch({ type: "setAdminLoading", payload: false });

      dispatch({ type: "setLoading", payload: true });
      try {
        const response = await _axios.get(
          buildLink("login", undefined, window.innerWidth)
        );
        const data = response.data;

        dispatch({ type: "setShowOver", payload: false });
       
        if (data.customer_id > 0) {
          dispatch({ type: "setLoged", payload: true });
          dispatch({ type: "setUsername", payload: data.username });
          dispatch({ type: "setUserId", payload: data.customer_id });
        } else {
          dispatch({ type: "setLoged", payload: false });
        }

        if (data.seller_logged !== "0" && data.seller_logged) {
          dispatch({ type: "setSeller", payload: true });
          Cookies.set("seller_id", data.seller_logged);
        }
      } catch (error) {
        console.error("Error during login fetch:", error);
      } finally {
        dispatch({ type: "setLoading", payload: false });
      }
    };

    initializeAdmin();
  }, [dispatch]); // Removed state.admin dependency

  // Logout
  function logout() {
    dispatch({ type: "setLoading", payload: true });
    //setShowUserMenu(false);
    _axios.post(buildLink("logout")).then(() => {
      checkLogin();
      dispatch({ type: "setSeller", payload: false });
      // Cookies.remove("seller_id");
      window.location.reload();
    });
  }

  // Check login
  function checkLogin() {
    dispatch({ type: "setLoading", payload: true });
    _axios
      .get(buildLink("login", undefined, window.innerWidth))
      .then((response) => {
        const data = response.data;

        dispatch({ type: "setShowOver", payload: false });
        console.log(data)
        if (data.customer_id > 0) {
          dispatch({ type: "setLoged", payload: true });
          dispatch({ type: "setUsername", payload: data.username });
          navigate("/");
        } else {
          dispatch({ type: "setLoged", payload: false });
        }
        dispatch({ type: "setLoading", payload: false });
      });
  }
  
  // console.log(state);
  return (
    <div className={`${state.loged ? ' text-dbasenavy' : 'relative  max-md:p-1.5   md:px-5 md:mx-auto '}`}>
      {width > 650 ? (
        <Link
          className={`flex justify-start  md:border-r cursor-pointer max-md:rounded-full max-md:border  md:shadow-md rounded-full  max-md:p-1  md:py-2 md:px-2 md:mx-auto ${state.loged ? 'bg-slate-200 md:bg-slate-100' : ''}`}
          onMouseEnter={() => {
            setShowmenu(true);
            dispatch({ type: "setViewProfileMenu", payload: true });
          }}
          onMouseLeave={() => {
            setShowmenu(false);
            dispatch({ type: "setViewProfileMenu", payload: false });
          }}
          onClick={() => setShowmenu(!showmenu)}
        >
          <span className=" text-sm my-auto font-semibold ">
            {state.loged ? <p>Profile</p> : <p>Login</p>}
          </span>
          <div className="flex my-auto justify-center items-center">
            <CiUser className=" text-2xl font-extrabold " />
          </div>
        </Link>
      ) : !state.loged ? (
        <Link to={`${path}/login`} className="header__user__auth__link">
          <div className="md:h-9 md:w-6  leading-8 mx-auto md:border md:border-dblue1 text-dbasenavy md:text-dblue1 flex justify-center items-center md:mb-1">
            <CiUser className=" text-2xl font-extrabold " />
          </div>
          <span className="header__icons-text whitespace-nowrap hidden md:block mt-1.5 text-d11">
            {state.loged ? <p>Profile</p> : <p>My Account</p>}
          </span>
        </Link>
      ) : (
        <Link
          className="header__user__auth__link"
          onClick={(e) => {
            e.preventDefault();
            setShowmenu(!showmenu);
          }}
        >
          <div className="relative md:border-r cursor-pointer max-md:rounded-full max-md:border  md:shadow-md rounded-full  max-md:p-1.5 border-dgrey5 md:py-2 md:px-6 md:mx-auto ">
            <CiUser className=" text-2xl font-extrabold " />
          </div>
          <span className="header__icons-text whitespace-nowrap hidden md:block mt-1.5 text-d11">
            {state.loged ? <p>Profile</p> : <p>My Account</p>}
          </span>
        </Link>
      )}

      {/* not logged hover div */}
      {showmenu && !state.loged && (
        // <div
        //   className="header__auth__menu-inner absolute -mt-0.5 -right-16 bg-dwhite1 w-60  p-5 z-20 shadow-lg border border-dgrey5 flex flex-col"
        //   onMouseEnter={() => setShowmenu(true)}
        //   onMouseLeave={() => setShowmenu(false)}
        // >
        //   <Link
        //     to={`${path}/login`}
        //     className="cursor-pointer bg-dblue1 hover:bg-dblack2 text-dwhite1 border border-dborderblack3 text-center p-1 mb-1.5 text-sm"
        //   >
        //     LOGIN
        //   </Link>
        //   <Link
        //     to={`${path}/login`}
        //     className="cursor-pointer bg-dwhite1 text-dblue1 hover:text-dblack2 border border-dborderblack3 text-center p-1 mb-1.5 text-sm"
        //   >
        //     SIGN UP
        //   </Link>
        // </div>
        <div
        className="mobile:hidden absolute z-30 bg-gradient-to-b -left-6 from-gray-50 to-white  rounded-xl    flex flex-col overflow-hidden header__auth__menu-inner  -mt-0.5  bg-dwhite1 w-40    shadow-lg border border-dgrey5 "
        onMouseEnter={() => setShowmenu(true)}
        onMouseLeave={() => setShowmenu(false)}
      >
        <Link
         to={`${path}/login`}
          onClick={() => setShowmenu(false)}
          className="cursor-pointer justify-center w-full  h-12 px-4 flex items-center text-base text-gray-700 hover:bg-blue-100 hover:text-blue-600 transition-all duration-200 ease-in-out"
        >
          LOGIN
        </Link>
        <Link
         to={`${path}/login`}
          className="cursor-pointer w-full justify-center h-12 px-4 flex items-center text-base text-gray-700 hover:bg-blue-100 hover:text-blue-600 transition-all duration-200 ease-in-out"
        >
          SIGN UP
        </Link>
      </div>
      )}
      {/* logged in hover div */}
      <div className="relative">
        {/* Logged in hover div */}
        {showmenu && state.loged && window.innerWidth > 650 && (
          <div
            className="mobile:hidden absolute z-30 right-0 bg-gradient-to-b -left-10 from-gray-50 to-white w-64 rounded-xl shadow-xl -bottom-[300px] mt-1 flex flex-col overflow-hidden border border-gray-200"
            onMouseEnter={() => setShowmenu(true)}
            onMouseLeave={() => setShowmenu(false)}
          >
            <div className="text-gray-600 bg-gray-100 h-14 px-4 flex items-center text-lg font-semibold border-b border-gray-200">
              {/* to make the first letter of the name uppercase */}
              Welcome{" "}
              {state?.username
                ? state.username.charAt(0).toUpperCase() +
                  state.username.slice(1)
                : ""}
            </div>
            <Link
              to={`${path}/account/profile`}
              onClick={() => setShowmenu(false)}
              className="cursor-pointer w-full text-left h-12 px-4 flex items-center text-base text-gray-700 hover:bg-blue-100 hover:text-blue-600 transition-all duration-200 ease-in-out"
            >
              Account
            </Link>
            <Link
              to={`${path}/account/orders`}
              onClick={() => setShowmenu(false)}
              className="cursor-pointer w-full text-left h-12 px-4 flex items-center text-base text-gray-700 hover:bg-blue-100 hover:text-blue-600 transition-all duration-200 ease-in-out"
            >
              Orders
            </Link>
            <Link
              to={`${path}/account/user-info`}
              onClick={() => setShowmenu(false)}
              className="cursor-pointer w-full text-left h-12 px-4 flex items-center text-base text-gray-700 hover:bg-blue-100 hover:text-blue-600 transition-all duration-200 ease-in-out"
            >
              User Information
            </Link>
            <Link
              to={`${path}/account/addresses`}
              onClick={() => setShowmenu(false)}
              className="cursor-pointer w-full text-left h-12 px-4 flex items-center text-base text-gray-700 hover:bg-blue-100 hover:text-blue-600 transition-all duration-200 ease-in-out"
            >
              Address Information
            </Link>
            <Link
              onClick={() => logout()}
              className="cursor-pointer w-full text-left h-12 px-4 flex items-center text-base text-red-600 hover:bg-red-100 hover:text-red-700 transition-all duration-200 ease-in-out bg-gray-100 border-t border-gray-200"
            >
              Log out
            </Link>
          </div>
        )}
      </div>
      {showmenu && state.loged && window.innerWidth < 650 && (
        <div
          className={`fixed inset-0 bg-slate-50 overflow-hidden z-30 transition-transform ${
            showmenu ? "animate-slideIn" : "animate-slideOut"
          }`}
          style={{ fontFamily: 'Inter, "Noto Sans", sans-serif' }}
        >
                      <div className="w-full bg-dborderblack2 flex justify-between p-3">
              <div
                onClick={() => setShowmenu(!showmenu)}
                className="text-[#ffffff] flex size-12 shrink-0 items-center"
                data-icon="ArrowLeft"
                data-size="24px"
                data-weight="regular"
              >
                <svg
                className="cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  fill="currentColor"
                  viewBox="0 0 256 256"
                >
                  <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
                </svg>
                <div className="flex flex-col ml-3 font-bold text-start">
                My Account
                <div className="font-sans ">
                <span className="text-[#ffffff] text-d13">{state?.email}</span>
                </div>
                </div>
              </div>

              <div className="flex w-12 items-center ">
                <div className="flex max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-12 bg-transparent text-[#0e141b] gap-2 text-base font-bold leading-normal tracking-[0.015em] min-w-0 p-0">
                  <div className="rounded-full border border-[#ffffff] text-[#ffffff] px-1">
                    <CiUser className=" text-2xl font-extrabold " />
                  </div>
                </div>
              </div>
            </div>
          <div className="flex flex-col w-full bg-slate-50 p-4 pb-2 ">


            <div className="flex-1 overflow-auto text-start px-4 animate-slideOut">
              <div className="flex flex-col bg-slate-50 ">
                <div className="flex items-center gap-4 h-14 justify-between border-b  ">
                  <p className=" text-base font-normal leading-normal flex-1 truncate">
                    Name
                  </p>
                  <div className="shrink-0 font-black flex size-7 items-center justify-center text-blue-900">
                    {state?.username
                      ? state.username.charAt(0).toUpperCase() +
                        state.username.slice(1)
                      : ""}
                  </div>
                </div>


                <Link 
                to={`${path}/account/profile`}
                onClick={() => setShowmenu(false)}
                className="flex items-center gap-4 h-14 justify-between cursor-pointer hover:bg-blue-100 hover:text-blue-700 rounded">
                  <p className=" text-base font-normal leading-normal flex-1 truncate px-1">
                    Account
                  </p>
                  <div className="shrink-0 hover:text-blue-700 flex size-7 items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      fill="currentColor"
                      viewBox="0 0 256 256"
                    >
                      <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
                    </svg>
                  </div>
                </Link>
                <Link
                              to={`${path}/account/orders`}
                              onClick={() => setShowmenu(false)}
                              className="flex items-center gap-4 h-14 justify-between cursor-pointer hover:bg-blue-100 hover:text-blue-700 rounded">
                  <p className=" text-base font-normal leading-normal flex-1 truncate px-1">
                    Orders
                  </p>
                  <div className="shrink-0 hover:text-blue-700 flex size-7 items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      fill="currentColor"
                      viewBox="0 0 256 256"
                    >
                      <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
                    </svg>
                  </div>
                </Link>
                <Link
                  to={`${path}/account/user-info`}
                  onClick={() => setShowmenu(false)}
                   className="flex items-center gap-4 h-14 justify-between cursor-pointer hover:bg-blue-100 hover:text-blue-700 rounded">
                  <p className=" text-base font-normal leading-normal flex-1 truncate px-1">
                    User Information
                  </p>
                  <div className="shrink-0 hover:text-blue-700] flex size-7 items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      fill="currentColor"
                      viewBox="0 0 256 256"
                    >
                      <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
                    </svg>
                  </div>
                </Link>
                <Link
                              to={`${path}/account/addresses`}
                              onClick={() => setShowmenu(false)}
                               className="flex items-center gap-4 h-14 justify-between cursor-pointer hover:bg-blue-100 hover:text-blue-700 rounded">
                  <p className=" text-base font-normal leading-normal flex-1 truncate px-1">
                    Address Information
                  </p>
                  <div className="shrink-0 hover:text-blue-700 flex size-7 items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      fill="currentColor"
                      viewBox="0 0 256 256"
                    >
                      <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
                    </svg>
                  </div>
                </Link>
                <Link
                  className="flex items-center gap-4 h-14 justify-between cursor-pointer hover:bg-red-100 hover:text-red-700 rounded"
                  onClick={() => {
                    logout()
                    setShowmenu(false)
                    setLoading(true)
                   }}
                >
                  <p className=" text-base font-normal leading-normal flex-1 truncate px-1">
                    Logout
                  </p>
                  <div className="shrink-0  hover:text-red-700 flex size-7 items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      fill="currentColor"
                      viewBox="0 0 256 256"
                    >
                      <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
                    </svg>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && <div className=" fixed z-[9999] w-full h-full inset-0 bg-black bg-opacity-20 "></div>}
    </div>
  );
}

export default TopAccount;
