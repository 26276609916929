import React, { useState, useRef, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineShoppingBag } from "react-icons/hi";
import buildLink, { path } from "../urls";
import { CartContext } from "../contexts/CartContext";
import _axios from "../axios";
import { AccountContext } from "../contexts/AccountContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import product_image from "../assets/images/singleProduct.png";
import ImageFilter from "react-image-filter/lib/ImageFilter";
import { BiCartAdd, BiHeart } from "react-icons/bi";
import { WishlistContext } from "../contexts/WishlistContext";
import { AiFillHeart } from "react-icons/ai";
import image from "../assets/images/exclusive.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SingleProducCategory(props) {
  const [state, dispatch] = useContext(CartContext);
  const [quantity, setQuantity] = useState(1);
  const width = window.innerWidth;
  const navigate = useNavigate();
  //   const [hovered1, isHovered1] = useState(false);
  const [accountContext] = useContext(AccountContext);
  const [stateW, dispatchW] = useContext(WishlistContext);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const thumbImages = props.item.images && props.item.images.map(image => image.thumb);
    const allImages = props.item.images ? [props.item.thumb, ...thumbImages]:[props.item.thumb];
    setImages(allImages);
  }, [props.item]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ToSeoUrl(url) {
    // make the url lowercase
    var encodedUrl = url.toString().toLowerCase();

    // replace & with and
    encodedUrl = encodedUrl.split(/\&+/).join("-and-");

    // remove invalid characters
    encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");

    // remove duplicates
    encodedUrl = encodedUrl.split(/-+/).join("-");

    // trim leading & trailing characters
    encodedUrl = encodedUrl.trim("-");

    return encodedUrl;
  }

  function addToWishlist() {
    if (!accountContext.loged) {
      navigate("/login");
    } else {
      if (stateW.pIds.indexOf(props.item.product_id) > -1) {
        _axios
          .delete(
            buildLink("wishlist", undefined, window.innerWidth) +
              "&id=" +
              props.item.product_id
          )
          .then(() => {
            _axios
              .get(buildLink("wishlistCount", undefined, window.innerWidth))
              .then((response) => {
                if (response.data.success) {
                  dispatchW({
                    type: "setProductsCount",
                    payload: response.data.data.total,
                  });
                }
              });
          });

        _axios
          .get(buildLink("wishlist", undefined, window.innerWidth))
          .then((response) => {
            if (response.data.success) {
              dispatchW({
                type: "setProducts",
                payload: response.data.data.products,
              });
              // dispatchW({
              //   type: "setProductsCount",
              //   payload: response.data.total_product_count,
              // });
              dispatchW({
                type: "setTotals",
                payload: response.data.data.totals,
              });
              const ids = response.data.data.products.map((p) => p.product_id);
              dispatchW({
                type: "setProductIds",
                payload: ids,
              });
              dispatchW({
                type: "loading",
                payload: false,
              });
            } else {
              dispatch({
                type: "setProductsCount",
                payload: 0,
              });

              dispatch({
                type: "loading",
                payload: false,
              });
            }
          });
      } else {
        dispatchW({
          type: "setProductIds",
          payload: [...stateW.pIds, props.item.product_id],
        });

        _axios
          .post(
            buildLink("wishlist", undefined, window.innerWidth) +
              "&id=" +
              props.item.product_id
          )
          .then(() => {
            _axios
              .get(buildLink("wishlistCount", undefined, window.innerWidth))
              .then((response) => {
                if (response.data.success) {
                  dispatchW({
                    type: "setProductsCount",
                    payload: response.data.data.total,
                  });
                  // dispatchW({ type: "setPopupW", payload: true });
                }
              });
          });
      }
    }
  }

  function addToCart(e, product_id) {
    e.preventDefault();
    // setHasAddToCartError(false);
    // setAddingToCart(true);
    let obj = {
      product_id,
      quantity,
    };

    // if (hasOption) {
    //   let o = {};
    //   const op = optionParent.toString();
    //   o[op] = activeOption["product_option_value_id"];
    //   obj["option"] = o;
    //   console.log(o);
    // }
    let error = "";
    _axios
      .post(buildLink("cart", undefined, window.innerWidth), obj)
      .then((response) => {
        const data = response.data;
        if (data.success !== true) {
          // There is an error
          // setHasAddToCartError(true);
          // if (!hasOption) {
          //   error = data.errors;
          // } else {
          //   error = data.errors[0].errorMsg;
          // }
          navigate(
            `${path}/${props.item.name
              .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
              .replace(/\s+/g, "-")
              .replace("..", "")
              .replace("/", "-")
              .replace("---", "-")
              .replace("--", "-")
              .replace("/", "")}/p=${props.item.product_id}`
          );
          // setAddToCartError(error);
          // setAddingToCart(false);
        } else {
          dispatch({
            type: "loading",
            payload: true,
          });
          _axios
            .get(buildLink("cart", undefined, window.innerWidth))
            .then((response) => {
              dispatch({ type: "setShowMenu", payload: true });
              dispatch({
                type: "setProducts",
                payload: response.data.data.products,
              });

              dispatch({
                type: "setProductsCount",
                payload: response.data.data.total_product_count,
              });
              dispatch({
                type: "setTotals",
                payload: response.data.data.totals,
              });
              // dispatch({
              //   type: "loading",
              //   payload: false,
              // });
            });

          // setSuccessAdded(true);
          if (width > 650) {
            // setCartmenu(true);
            // props.showCartmenu();
            // setOverlay(true);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          } else {
            // setPopupC(true);
          }

          // setTimeout(() => {
          //   // setCountDown(false)
          //   setAddingToCart(false);
          // }, 3000);
        }
      });
  }

  return (
    <>
      <Link
        to={`${path}/${ToSeoUrl(props.item.name)}/p=${props.item.product_id}`}
        className="relative translate-y-0 hover:-translate-y-1 pb-2 hover:shadow-lg duration-300 transition-all shadow-dgrey4 block  group w-full "
      >
        <div className="relative  m-2.5 pt-5">
          {props.item.tag_image && (
            <div className=" absolute  right-1 top-1 max-md:w-[40px] max-md:h-[20px] w-[80px] h-[30px] z-50 ">
              <div className=" w-full relative rounded-md overflow-hidden">
                <img className=" w-full h-full" src={props.item.tag_image} />
              </div>
            </div>
          )}
          <div className=" rounded-lg  relative overflow-hidden">
            <div className=" absolute right-1 bottom-1 z-50">
              {" "}
              <button
                onClick={(e) => addToCart(e, props.item.product_id)}
                className=" transition-all group-hover:bg-opacity-100 duration-300 group-hover:text-dblack1 rounded-full p-2 bg-white text-dgrey9  bg-opacity-70"
              >
                <BiCartAdd className=" text-2xl" />
              </button>
            </div>
            <div className=" absolute left-1 bottom-1 z-50">
              {" "}
              <button
  onClick={(e) => {
    e.preventDefault();
    addToWishlist();
  }}
  className="transition-all group-hover:bg-opacity-100 duration-300 group-hover:text-dblack1 rounded-full p-2 bg-white bg-opacity-70"
>
  {stateW.pIds.indexOf(props.item.product_id) > -1 ? (
    <AiFillHeart className="text-dborderblack1 text-2xl" />
  ) : (
    <BiHeart className="text-dgrey9 text-2xl" />
  )}
</button>
            </div>

            {props.item.quantity === "0" && (
              <div
                className={
                  window.innerWidth > 650
                    ? "absolute z-20 text-red-700 text-d18 font-semibold w-full text-center  bottom-0"
                    : "absolute z-20 text-red-700  w-full text-center  bottom-0 "
                }
              >
                Out Of Stock
              </div>
            )}

            {/* {window.innerWidth < 650 ? (
            props.item.quantity === "0" ? (
              <ImageFilter
                image={props.item.thumb}
                filter={"duotone"} // see docs beneath
                colorOne={[96, 96, 96]}
                colorTwo={[255, 255, 255]}
              />
            ) : (
              <LazyLoadImage
                className="mr-1.5"
                style={{ height: "105px" }}
                src={props.item.thumb}
                alt={props.item.name}
                placeholderSrc={product_image}
              />
            )
          ) : */}
            {props.item.quantity === "0" ? (
              <ImageFilter
                image={props.item.thumb}
                filter={"duotone"} // see docs beneath
                colorOne={[96, 96, 96]}
                colorTwo={[255, 255, 255]}
              />
            ) : (
              <div className="relative">
                {props.item.images && props.item.images.length >0 ? (
                  <>
                    <Slider {...settings}>
                      {images.map((image, index) => (
                        <img
                          key={index}
                          loading="lazy"
                          className="object-cover w-full h-full"
                          src={image}
                          alt={props.item.name}
                          placeholderSrc={product_image}
                        />
                      ))}
                    </Slider>
                  </>
                ): (
                  <img
                    loading="lazy"
                    className="object-cover w-full h-full"
                    src={props.item.thumb}
                    alt={props.item.name}
                    placeholderSrc={product_image}
                  />
                )}
                <div className="absolute top-0 left-0 bg-red-500 text-white rounded-full text-xs md:text-sm lg:text-l font-bold w-6 h-6 md:w-8 md:h-8 lg:w-12 lg:h-12">
                  <p className="w-full flex items-center justify-center h-full">
                    {props.item.saving + "%"}
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* {window.innerWidth > 650 && props.item.quantity !== "0" && (
          <>
            {" "}
            <button
              onClick={(e) => addToCart(e, props.item.product_id)}
              className={`place-content-center text-dblue1 -bottom-2.5 flex items-center absolute h-12 ${
                hovered
                  ? "  bottom-0.5  inset-x-0  opacity-100 text-d16 bg-dgreyTransp2 hover:bg-black hover:opacity-70 hover:text-white w-full  duration-300 delay-100"
                  : "  -bottom-2.5 inset-x-0  opacity-0 text-d16 transition-all  duration-500 "
              } " "`}
            >
              {" "}
              <HiOutlineShoppingBag />{" "}
              <span className="ml-1 whitespace-nowrap uppercase">
                add to Basket
              </span>
            </button>
          </>
        )} */}
        </div>

        <div
          className={`flex flex-col md:mt-2 text-d17 px-4 ${
            props.item.quantity === "0" && "opacity-50"
          } `}
        >
          <span
            className={`text-left font-normal h-14 capitalize ${
              window.innerWidth > 650 ? "text-d15" : "text-d14"
            } `}
            dangerouslySetInnerHTML={{
              __html: props.item.name,
            }}
          ></span>
          {/* {window.innerWidth > 650 ? ( */}
          <div className="flex flex-row">
            <div className="flex flex-col text-left   w-1/2">
              <span className="w-full text-bold text-dblack2 font-bold">
                {props.item.special}
              </span>
              <div className=" flex flex-row  gap-2 justify-start ">
                <span className=" line-through text-d13">
                  {props.item.price}
                </span>
              </div>
              {/* {accountContext.admin && (
                <div className=" font-bold text-d14 -mb-2 w-full">
                  {" "}
                  {props.item.quantity}
                </div>
              )} */}
            </div>

            {/* <button className="border px-1 flex-auto border-dred1 text-red-500 text-d13 w-1/2">
              <span className="">{props.item.saving}% Discount</span>
            </button> */}
          </div>
          {/* ) : (
          <div className="flex flex-row text-d14 pt-5">
            <span className="flex flex-col text-left w-1/2 ">
              <span className="w-full text-dgrey12 -mt-2 mb-2 line-through text-d13">
                {props.item.price}
              </span>
              <span className="w-full font-bold text-d18 -mb-2 text-blue-600">
                {props.item.special}
              </span>
              {accountContext.admin && (
                <span className=" font-bold text-d14 -mb-2  w-full ">
                  {" "}
                  {props.item.quantity}
                </span>
              )}
            </span>

            <span className=" border-dred1 text-red-500 flex  text-d11  w-1/2  border">
              {props.item.saving}% Discount
            </span>
          </div>
        )} */}
        </div>
      </Link>
    </>
  );
}
